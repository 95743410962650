import * as d3 from "d3";
import * as Lib from "../Constants/RecordingConstants";

const WAVEFORM_BLACK = "#3D2525";
const LIGHT_GREY = "#CECECE";
const DARK_GREY = "#ACADAC";

export const calcRowSeconds = (width) => Math.floor(width / Lib.PX_PER_SECOND);

export const xScale = (domain, range, scale) =>
  d3
    .scaleLinear()
    .domain([0, domain])
    .range([0, range * scale]);

export const yScale = (height, inverted, scale) => {
  const range = [height, 0];
  if (inverted) {
    range.reverse();
  }

  // TODO this domain needs to be set from properties of the recording
  const mvSwing = Lib.SAMPLE_UNITS_PER_MV * Lib.MV_BANDWIDTH;
  return d3
    .scaleLinear()
    .domain([mvSwing * -1, mvSwing])
    .range(range);
};

const calpulseYscale = (height) => {
  const range = [height, 0];
  const mvSwing = Lib.SAMPLE_UNITS_PER_MV * Lib.MV_BANDWIDTH;
  return d3
    .scaleLinear()
    .domain([mvSwing * -1, mvSwing])
    .range(range);
};

export const paint1LeadWaveform = (
  samples,
  height,
  context,
  scale,
  inverted,
  sliceSize,
  stripWidthPx,
  rows,
  calpulse = true
) => {
  /* eslint-disable no-param-reassign */
  let sampleSlice;
  let step = 0;
  // shiftPx and shiftSamp for moving waveform to accomodate calpulse
  const shiftPx = Lib.PX_PER_SECOND / 2;

  for (let j = 0; j < rows; j += 1) {
    const shiftSamp = j === 0 && calpulse ? shiftPx * Lib.SAMPLES_PER_PIXEL : 0;
    sampleSlice = samples.slice(step, step + sliceSize - shiftSamp);
    const returnX = (d, k) => xScale(sliceSize, stripWidthPx, scale)(k);
    const returnY = (d) => yScale(height, inverted, scale)(d);
    const line = d3.line().x(returnX).y(returnY).context(context);

    if (j !== 0) {
      context.translate(0, height);
    }
    context.beginPath();

    if (j === 0 && calpulse) {
      context.translate(shiftPx, 0);
    } else if (j === 1 && calpulse) {
      context.translate(-shiftPx, 0);
    }
    line(sampleSlice);
    context.strokeStyle = WAVEFORM_BLACK;
    context.lineWidth = 1.5;
    context.stroke();

    step += sampleSlice.length;
  }
};

export const paint6LeadWaveform = (
  samples,
  height,
  context,
  scale,
  inverted,
  sliceSize,
  stripWidthPx,
  calpulse = true
) => {
  /* eslint-disable no-param-reassign */
  // shiftPx for moving waveform to accomodate calpulse
  const shiftPx = Lib.PX_PER_SECOND / 2;

  const returnX = (d, k) => xScale(sliceSize, stripWidthPx, scale)(k);
  const returnY = (d) => yScale(height, inverted, scale)(d);
  const line = d3.line().x(returnX).y(returnY).context(context);
  // converting object into array to make sure that leads are in the right order
  const allSamples = [
    samples.leadI,
    samples.leadII,
    samples.leadIII,
    samples.aVR,
    samples.aVL,
    samples.aVF,
  ];

  allSamples.forEach((lead, i) => {
    if (i !== 0) {
      // translating each lead half the height of a full row
      context.translate(0, height / 2);
    }
    context.beginPath();

    if (i === 0 && calpulse) {
      context.translate(shiftPx, 0);
    }
    line(allSamples[i]);
    context.strokeStyle = WAVEFORM_BLACK;
    context.lineWidth = 1.5;
    context.stroke();
  });
};
export const paintGrid = (context, rowSeconds, height, width, scale) => {
  const secondsScale = () =>
    d3
      .scaleLinear()
      .domain([0, 1])
      .range([0, Lib.PX_PER_SECOND * scale]);
  renderXGrid(context, height, secondsScale, rowSeconds);
  renderXGridDark(context, height, secondsScale, rowSeconds);
  renderYGrid(context, height, scale, width);
};
export const renderXGrid = (context, height, secondsScale, rowSeconds) => {
  /* eslint-disable no-param-reassign */
  let i = 0;
  while (i <= rowSeconds) {
    context.beginPath();
    context.moveTo(secondsScale()(i) + Lib.SVG_OFFSET, Lib.SVG_OFFSET);
    context.lineTo(secondsScale()(i) + Lib.SVG_OFFSET, height);
    context.strokeStyle = LIGHT_GREY;
    context.lineWidth = 1;
    context.stroke();
    i += 0.2;
  }
};
export const renderXGridDark = (context, height, secondsScale, rowSeconds) => {
  /* eslint-disable no-param-reassign */
  let i = 0;
  while (i <= rowSeconds) {
    context.beginPath();
    context.moveTo(secondsScale()(i) + Lib.SVG_OFFSET, Lib.SVG_OFFSET);
    context.lineTo(secondsScale()(i) + Lib.SVG_OFFSET, height);
    context.strokeStyle = DARK_GREY;
    context.lineWidth = 1;
    context.stroke();
    i += 1;
  }
};

export const renderYGrid = (context, height, scale, width) => {
  /* eslint-disable no-param-reassign */
  let i = 0;
  const redAt = 100;

  while (i * scale < height) {
    context.beginPath();
    context.moveTo(0, i * scale + Lib.SVG_OFFSET);
    context.lineTo(width + 1, i * scale + Lib.SVG_OFFSET);
    if (i % redAt === 0) {
      context.strokeStyle = DARK_GREY;
    } else {
      context.strokeStyle = LIGHT_GREY;
    }
    context.lineWidth = 1;
    context.stroke();
    i += Lib.PX_PER_MM * 5;
  }
};

export const renderCalpulse = (height, sliceSize, stripWidthPx) => {
  // console.log({ height, sliceSize, stripWidthPx });
  const line = d3
    .line()
    .x((d) => xScale(sliceSize, stripWidthPx, 1)(d[0]))
    .y((d) => calpulseYscale(height)(d[1]));
  // the first array member is sample index
  // the second is mV
  const data = [
    [30, 0],
    [60, 0],
    [60, Lib.SAMPLE_UNITS_PER_MV],
    [120, Lib.SAMPLE_UNITS_PER_MV],
    [120, 0],
    [150, 0],
  ];

  return line(data);
};
