import { makeStyles } from "@material-ui/core/styles";
import {
  jungleGreen,
  pineGreen,
  white,
  snowWhite,
  pantoneRed,
} from "../../consts/colors";
import { fontFamily } from "../../Assets/styles/commonStyles";

export const useStyles = makeStyles({
  container: {
    height: "100vh",
    overflow: "auto",
    backgroundColor: snowWhite,
    padding: "1rem",
    paddingTop: "0",
  },
  topMargin: {
    marginTop: "2rem",
  },
  paperRoot: {
    marginTop: "1rem",
    padding: "2.5rem",
    minHeight: "80vh",
    marginBottom: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
  },
  errorMessage: {
    fontFamily,
    color: pantoneRed,
    fontSize: "16px",
    lineHeight: "20px",
  },
  clearButton: {
    color: jungleGreen,
    fontFamily,
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    width: "161px",
  },
  exportButton: {
    backgroundColor: pineGreen,
    color: white,
    fontFamily,
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    padding: "12px 20px",
    width: "161px",
    "&:hover": {
      backgroundColor: jungleGreen,
    },
  },
});
