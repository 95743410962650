export const LANGUAGE_MAPPER=[
    {
      code:'fr',
      name:'French',
    },
    {
      code:'it',
      name:'Italian',
    },
    {
      code:'es',
      name:'Spanish',
    },
    {
      code:'de',
      name:'German',
    },
    {
      code:'nl',
      name:'Dutch',
    },
]
