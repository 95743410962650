import { makeStyles } from "@material-ui/core/styles";
import { fontFamily } from "../../Assets/styles/commonStyles";
import { black } from "../../consts/colors";

export const useStyles = makeStyles({
  paperRoot: {
    display: "flex",
    justifyContent: "space-between",
  },
  containerGrid: {
    display: "flex",
    justifyContent: "space-between",
  },
  row: {
    display: "flex",
    marginTop: "1rem",
    marginBottom: "1rem",
    "& > p": {
      fontFamily,
      letterSpacing: "-0.2px",
      color: black,
    },
  },
  headingColumn: {
    fontFamily,
    width: "120px",
    fontSize: "16px",
    lineHeight: "20px",
    wordBreak: "break-all",
  },
  dataColumn: {
    fontFamily,
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: "bold",
    wordBreak: "break-all",
  },
  interpretation: {
    textTransform: "capitalize",
  },
  rightRow: {
    display: "flex",
    justifyContent: "end",
    marginTop: "1rem",
    marginBottom: "1rem",
    "& > p": {
      fontFamily,
      letterSpacing: "-0.2px",
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  leftMargin: {
    marginLeft: "3px",
  },
  emptyBox: {
    height: "20px",
  },
});
