export const ageCalculate = (dob) => {
  const dobDate = new Date(dob);
  const year = dobDate.getFullYear();
  const currentYear = new Date().getFullYear();
  const age = currentYear - year;
  const formattedAge = age.toString().padStart(2, "0");
  return formattedAge;
};

export const ageCalculateFromString = (dateString) => {
  const dateArr = dateString.split("-");
  const year = dateArr[0];
  const age = new Date().getFullYear() - year;
  const formattedAge = age.toString().padStart(2, "0");
  return formattedAge;
};
