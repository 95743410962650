import { makeStyles, withStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { TableSortLabel } from "@material-ui/core";
import {
  black,
  jungleGreen,
  lightGreen,
  white,
  snowWhite,
  darkBluishGrey,
} from "../../consts/colors";
import { fontFamily } from "../../Assets/styles/commonStyles";

export const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: jungleGreen,
    },
  },
  typography: {
    fontFamily,
  },
});

export const StyledTableSortLabel = withStyles((theme) => ({
  icon: {
    color: black,
    width: "25px",
    fontSize: "25px",
    "& path": {
      fill: black,
    },
  },
}))(TableSortLabel);

export const useStyles = makeStyles({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: snowWhite,
    },
    "&:nth-of-type(even)": {
      backgroundColor: white,
    },
    "&:hover": {
      backgroundColor: `${lightGreen} !important`,
    },
    fontFamily,
    fontSize: "16px",
  },
  clickableRow: {
    cursor: "pointer",
  },
  tableCell: {
    fontSize: "16px",
    textTransform: "capitalize",
    textOverflow: "ellipsis",
    maxWidth: "180px",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  tableColumnHeading: {
    fontWeight: "bold",
  },
  customWidth: {
    minWidth: "180px",
  },
  tooltip: {
    fontFamily,
    background: snowWhite,
    color: darkBluishGrey,
    fontSize: "14px",
    border: ".5px solid #999999",
    textTransform: "capitalize",
  },
  message: {
    fontFamily,
    fontWeight: "600",
    fontSize: "20px",
    textAlign: "center",
  },
  caughtUp: {
    marginTop: "3rem",
  },
});
