export const EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES = {
  NO_ANALYSIS: "NO_ANALYSIS",
  UNCLASSIFIED: "UNCLASSIFIED",
  TOO_SHORT: "TOO_SHORT",
  TOO_LONG: "TOO_LONG",
  NORMAL: "NORMAL",
  TACHYCARDIA: "TACHYCARDIA",
  BRADYCARDIA: "BRADYCARDIA",
  AFIB: "AFIB",
  UNREADABLE: "UNREADABLE",
  "SINUS_RHYTHM,MULTIPLE_PACS": "SINUS_RHYTHM,MULTIPLE_PACS",
  "SINUS_RHYTHM,WIDE_QRS": "SINUS_RHYTHM,WIDE_QRS",
  "SINUS_RHYTHM,MULTIPLE_PVCS": "SINUS_RHYTHM,MULTIPLE_PVCS",
  SINUS_RHYTHM: "SINUS_RHYTHM",
  NORMAL_SINUS_RHYTHM: "NORMAL_SINUS_RHYTHM",
  HEART_RATE_UNDER_50: "HEART_RATE_UNDER_50",
  HEART_RATE_OVER_120: "HEART_RATE_OVER_120",
  INCONCLUSIVE_POOR_READING: "INCONCLUSIVE_POOR_READING",
  INCONCLUSIVE: "INCONCLUSIVE",
  UNSUPPORTED_NONE: "Unsupported,none", //TODO : will be removed in future once interpretation from backend gets fixed.
};

export const EKG_ALGORITHM_DETERMINATION_TYPES = {
  // Expected results
  afib: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.AFIB,
  tachycardia: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TACHYCARDIA,
  bradycardia: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.BRADYCARDIA,
  normal: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NORMAL,
  noise: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.UNREADABLE,
  unreadable: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.UNREADABLE,
  unclassified: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.UNCLASSIFIED,
  undetermined: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.UNCLASSIFIED,
  "too short": EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TOO_SHORT, // legacy support
  too_short: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TOO_SHORT,
  "too long": EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TOO_LONG, // legacy support
  too_long: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TOO_LONG,
  none: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NO_ANALYSIS,
  unknown: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NO_ANALYSIS, // legacy support
  no_analysis: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NO_ANALYSIS,
  "sinus_rhythm,multiple_pacs":
    EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES["SINUS_RHYTHM,MULTIPLE_PACS"],
  "sinus_rhythm,wide_qrs":
    EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES["SINUS_RHYTHM,WIDE_QRS"],
  "sinus_rhythm,multiple_pvcs":
    EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES["SINUS_RHYTHM,MULTIPLE_PVCS"],
  sinus_rhythm: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.SINUS_RHYTHM,
  normal_sinus_rhythm:
    EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NORMAL_SINUS_RHYTHM,
  sinusRhythm: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.SINUS_RHYTHM,
  atrialFibrillation: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.AFIB,
  inconclusiveLowHeartRate:
    EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.HEART_RATE_UNDER_50,
  inconclusiveHighHeartRate:
    EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.HEART_RATE_OVER_120,
  inconclusivePoorReading:
    EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.INCONCLUSIVE_POOR_READING,
  inconclusiveOther: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.INCONCLUSIVE,
  unsupported: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.UNSUPPORTED_NONE, //TODO : will be removed in future once interpretation from backend gets fixed.

  // CYA results
  "": EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NO_ANALYSIS,
  [null]: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NO_ANALYSIS,
};

export const getEKGAlgorithmDeterminationDisplayText = (
  ekgAlgorithmDetermination,
  algorithmPackage,
  bpm
) => {
  const ekgAlgorithmDeterminationGroupType =
    EKG_ALGORITHM_DETERMINATION_TYPES[ekgAlgorithmDetermination];
  let displayText;

  switch (ekgAlgorithmDeterminationGroupType) {
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.UNCLASSIFIED:
      displayText = "Unclassified";
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NORMAL:
      displayText = "Normal Sinus Rhythm"; // Update Kardia determination - "Normal" to "Normal Sinus Rhythm"
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.AFIB:
      if (algorithmPackage === "KAIv2") {
        displayText = "Atrial Fibrillation";
      } else if (algorithmPackage === "apple") {
        displayText = "Atrial Fibrillation";
      } else {
        displayText = "Possible Atrial Fibrillation";
      }
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TACHYCARDIA:
      displayText = "Tachycardia";
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.BRADYCARDIA:
      displayText = "Bradycardia";
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TOO_SHORT:
      displayText = "Short";
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TOO_LONG:
      displayText = "Long";
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.UNREADABLE:
      displayText = "Unreadable";
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES[
      "SINUS_RHYTHM,MULTIPLE_PACS"
    ]:
      displayText = "Sinus Rhythm with Supraventricular Ectopy";
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES["SINUS_RHYTHM,WIDE_QRS"]:
      displayText = "Sinus Rhythm with Wide QRS";
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES[
      "SINUS_RHYTHM,MULTIPLE_PVCS"
    ]:
      displayText = "Sinus Rhythm with Premature Ventricular Contractions";
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.SINUS_RHYTHM:
      if (algorithmPackage === "KAIv2") {
        displayText = "Normal Sinus Rhythm";
      } else if (algorithmPackage === "apple") {
        if (bpm > 150) {
          displayText = "Heart Rate Over 150";
        } else if (bpm >= 120) {
          displayText = "Heart Rate Over 120";
        } else {
          displayText = "Sinus Rhythm";
        }
      } else {
        displayText = "Normal Sinus Rhythm"; // Update Kardia determination - "Normal" to "Normal Sinus Rhythm"
      }
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NORMAL_SINUS_RHYTHM:
      displayText = "Normal Sinus Rhythm"; // Update Kardia determination - "Normal" to "Normal Sinus Rhythm"
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.HEART_RATE_UNDER_50:
      displayText = "Heart Rate Under 50";
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.HEART_RATE_OVER_120:
      if (bpm > 150) {
        displayText = "Heart Rate Over 150";
      } else {
        displayText = "Heart Rate Over 120";
      }
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.INCONCLUSIVE_POOR_READING:
      displayText = "Poor Recording";
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.INCONCLUSIVE:
      displayText = "Inconclusive";
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.unsupported: //TODO : will be removed in future once interpretation from backend gets fixed.
      displayText = "Atrial Fibrillation";
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NO_ANALYSIS:
    default:
      displayText = "No Analysis";
      break;
  }
  return displayText;
};
