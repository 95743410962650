/*eslint-disable*/
import jwtdecode from 'jwt-decode'
import { GoAPI } from '../Axios/axios'

export  const getToken = () => {
    let token = localStorage.getItem("kardiapro_id_token")
    if (!token || !(timeRemainingOnTokenMs_Local(token)/60000 > 5)) {
        //redirect to home
        // console.log("Token Expired or Invalid - Redirecting to Login");
        // window.location.replace("http://" + window.location.host + "/login");
        GoAPI.get(`/i/v1/jwt`,
        {
            headers: { "Authorization": `Bearer ${token}` },
        })
        .then(res=>{
            localStorage.setItem("kardiapro_id_token",res.data.jwt);
        })
        .catch(err=>{
            console.log(err);
        })
        return token; 
    }
    else {
         return token;
    }
}


const timeRemainingOnTokenMs_Local = (token) => {
    //const token = getToken()
    const tokenExists = token && token.length > 0

    // return no time left on token if token does not exist
    if (!tokenExists) {
        return 0
    }
    let decoded
    try {
        decoded = jwtdecode(token)
        const tokenExpiresAt = new Date(decoded.exp * 1000)
        const now = new Date()
        const remainingJwtTime = Math.floor(tokenExpiresAt.getTime() - now.getTime()) // time remaining in milliseconds
        // console.log("timeRemainingOnTokenMs " + (remainingJwtTime / 60000) + " minutes")
        return remainingJwtTime < 0 ? 0 : remainingJwtTime
    } catch (err) {
        console.log(err)
        //return 0
    }
}


export const timeRemainingOnTokenMs = () => {
    const token = getToken()
    const tokenExists = token && token.length > 0

    // return no time left on token if token does not exist
    if (!tokenExists) {
        return 0
    }
    let decoded
    try {

        decoded = jwtdecode(token)
        const tokenExpiresAt = new Date(decoded.exp * 1000)
        const now = new Date()
        const remainingJwtTime = Math.floor(tokenExpiresAt.getTime() - now.getTime()) // time remaining in milliseconds
        //console.log("timeRemainingOnTokenMs " + (remainingJwtTime / 60000) + " minutes")
        return remainingJwtTime < 0 ? 0 : remainingJwtTime
    } catch (err) {
        console.log(err)
        //return 0
    }
}

export const setResetIdleTime = () => {
    const now = new Date()
    localStorage.setItem("kardiapro_idle_time_reset_at", now)
}

export function setRegion(url) {
    if (url.indexOf("eu") !== -1) {
      localStorage.setItem("crpRegion", "eu");
    } else {
      localStorage.setItem("crpRegion", "us");
    }
  }