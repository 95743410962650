/* eslint-disable */
import axios from "axios";


export let CrpAPI 
export let GoAPI 
export let KardiaTeamsAPI 

export const setApiUrls = (crpurl,gourl,kardiaurl) => {
  CrpAPI  = axios.create({
    baseURL: crpurl,
  });
  GoAPI = axios.create({
    baseURL: gourl,
  });
  KardiaTeamsAPI = axios.create({
    baseURL: kardiaurl,
  });
}

