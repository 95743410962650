import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";

import {
  StyledTableSortLabel,
  useStyles,
  defaultMaterialTheme,
} from "./CustomTableStyles";
import EcgLoader from "../Shared/EcgLoader";
import { getEKGAlgorithmDeterminationDisplayText } from "../../consts/recordingMaps";
import {
  changeDateTimeFormat,
  getMeridiem,
} from "../../Containers/utils/changeTimeFormat";

//Usage : expects following =>
// tableHeaders : Array
// sortIcon : function
// changedFilter : function
// sortLabel1 (Time Left) : String
// sortLabel2 (Time Submitted) : String
// tab : number
// totalData : number
// tableBodyData : array
// fetchMoreData : function to get more data
// isScrollTop : bolean which tells if scroll is at the top or not
// isSearchButtonClicked
// allCaughtUpCondition
// noResultCondition
// incomingListDataNotAvailable,
// completedListDataNotAvailable,
// onClick : function
// status1,
// status2,
// status3,
// status4,
// status1Component,
// status2Component,
// status3Component,
// status4Component,
// allCaughtUpConditionCompletedTab,
// tab1NoDataText,
// tab2NoDataText,
// incomingListDataNotAvailableMessage,
// completedListDataNotAvailableMessage,

function CustomTable(props) {
  const {
    tableHeaders,
    sortIcon,
    changedFilter,
    transformTimeLeft,
    sortLabel1,
    sortLabel2,
    tab,
    totalData,
    tableBodyData,
    fetchMoreData,
    isShowReviewClicked,
    isScrollTop,
    isSearchButtonClicked,
    allCaughtUpCondition,
    noResultCondition,
    incomingListDataNotAvailable,
    completedListDataNotAvailable,
    onClick,
    isNotClickable,
    status1,
    status2,
    status3,
    status4,
    status1Component,
    status2Component,
    status3Component,
    status4Component,
    allCaughtUpConditionCompletedTab,
    tab1NoDataText,
    tab2NoDataText,
    incomingListDataNotAvailableMessage,
    completedListDataNotAvailableMessage,
    isLoadingData,
  } = props;

  const isReportSubmitted = useSelector((state) => state.ekgReview.showToast);
  const classes = useStyles();

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight - 300,
  });

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight - 300,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  return (
    <TableContainer>
      <ThemeProvider theme={defaultMaterialTheme}>
        <InfiniteScroll
          dataLength={tableBodyData.length}
          next={fetchMoreData}
          hasMore={tableBodyData.length < totalData}
          loader={
            !isScrollTop && !isSearchButtonClicked && !isShowReviewClicked ? (
              <EcgLoader />
            ) : (
              ""
            )
          }
          height={
            isLoadingData && isReportSubmitted
              ? screenSize.dynamicHeight - 50
              : isLoadingData && !isReportSubmitted
              ? screenSize.dynamicHeight
              : allCaughtUpCondition ||
                allCaughtUpConditionCompletedTab ||
                incomingListDataNotAvailable ||
                completedListDataNotAvailable
              ? 190
              : noResultCondition
              ? 0
              : isReportSubmitted
              ? screenSize.dynamicHeight - 50
              : screenSize.dynamicHeight
          }
          scrollableTarget="scrollableDiv"
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {tableHeaders.map((column) => (
                  <TableCell
                    key={column.id}
                    className={`${classes.tableCell} ${
                      classes.tableColumnHeading
                    } ${
                      column.label !== "Location" &&
                      column.label !== "Status" &&
                      column.label !== "Time Left" &&
                      column.label !== "Report ID"
                        ? classes.customWidth
                        : ""
                    }`}
                  >
                    {column.disableSorting ? (
                      column.label
                    ) : (
                      <StyledTableSortLabel
                        active={true}
                        direction={sortIcon(
                          column.label === sortLabel1 && tab === 2
                            ? sortLabel2
                            : column.label
                        )}
                        onClick={() => {
                          changedFilter(
                            column.label === sortLabel1 && tab === 2
                              ? sortLabel2
                              : column.label
                          );
                        }}
                        IconComponent={KeyboardArrowDownRoundedIcon}
                      >
                        {column.label === sortLabel1 && tab === 2
                          ? sortLabel2
                          : column.label}
                      </StyledTableSortLabel>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableBodyData.length > 0 &&
                tableBodyData.map((row, index) => {
                  return (
                    <TableRow
                      className={`${classes.root} ${
                        isNotClickable ? "" : classes.clickableRow
                      }`}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      onClick={() => onClick(row.id)}
                    >
                      <Tooltip
                        title={`${row.lastName}, ${row.firstName}`}
                        classes={{ tooltip: classes.tooltip }}
                        enterNextDelay={200}
                        leaveDelay={0}
                        leaveTouchDelay={0}
                      >
                        <TableCell className={`${classes.tableCell}`}>
                          {`${row.lastName}, ${row.firstName}`}
                        </TableCell>
                      </Tooltip>
                      <TableCell className={`${classes.tableCell}`}>
                        {row.reportId}
                      </TableCell>
                      <Tooltip
                        title={getEKGAlgorithmDeterminationDisplayText(
                          row.interpretation,
                          row.algorithmPackage,
                          row.heartRate
                        )}
                        classes={{ tooltip: classes.tooltip }}
                        enterNextDelay={200}
                        leaveDelay={0}
                        leaveTouchDelay={0}
                      >
                        <TableCell className={`${classes.tableCell}`}>
                          {getEKGAlgorithmDeterminationDisplayText(
                            row.interpretation,
                            row.algorithmPackage,
                            +row.heartRate
                          )}
                        </TableCell>
                      </Tooltip>
                      <Tooltip
                        title={`${changeDateTimeFormat(row.timeRequested)}
                          ${getMeridiem(row.timeRequested)}`}
                        classes={{ tooltip: classes.tooltip }}
                        enterNextDelay={200}
                        leaveDelay={0}
                        leaveTouchDelay={0}
                      >
                        <TableCell className={`${classes.tableCell}`}>
                          {changeDateTimeFormat(row.timeRequested)}
                          {getMeridiem(row.timeRequested)}
                        </TableCell>
                      </Tooltip>
                      {row.timeleft !== undefined && (
                        <TableCell className={`${classes.tableCell} `}>
                          {row.timeleft !== undefined
                            ? transformTimeLeft(row.timeleft)
                            : ""}
                        </TableCell>
                      )}
                      {row.timeSubmitted !== undefined && (
                        <Tooltip
                          title={
                            changeDateTimeFormat(row.timeSubmitted) +
                            getMeridiem(row.timeSubmitted)
                          }
                          classes={{ tooltip: classes.tooltip }}
                          enterNextDelay={200}
                          leaveDelay={0}
                          leaveTouchDelay={0}
                        >
                          <TableCell className={`${classes.tableCell} `}>
                            {row.timeSubmitted !== undefined
                              ? changeDateTimeFormat(row.timeSubmitted) +
                                getMeridiem(row.timeSubmitted)
                              : ""}
                          </TableCell>
                        </Tooltip>
                      )}
                      <TableCell className={`${classes.tableCell}`}>
                        {row.status === status1 && status1Component}
                        {row.status === status2 && status2Component}
                        {row.status === status3 && status3Component}
                        {row.status === status4 && status4Component}
                      </TableCell>
                      <TableCell className={`${classes.tableCell}`}>
                        {row.location}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {allCaughtUpCondition &&
            !incomingListDataNotAvailable &&
            !isLoadingData && (
              <Typography className={`${classes.message} ${classes.caughtUp}`}>
                {tab1NoDataText}
              </Typography>
            )}
          {incomingListDataNotAvailable && tab === 1 && !isLoadingData && (
            <Typography className={`${classes.message} ${classes.caughtUp}`}>
              {incomingListDataNotAvailableMessage}
            </Typography>
          )}
          {allCaughtUpConditionCompletedTab &&
            !isLoadingData &&
            !completedListDataNotAvailable && (
              <Typography className={`${classes.message} ${classes.caughtUp}`}>
                {tab2NoDataText}
              </Typography>
            )}
          {completedListDataNotAvailable && tab === 2 && !isLoadingData && (
            <Typography className={`${classes.message} ${classes.caughtUp}`}>
              {completedListDataNotAvailableMessage}
            </Typography>
          )}
        </InfiniteScroll>
      </ThemeProvider>
    </TableContainer>
  );
}

export default CustomTable;
