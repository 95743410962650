import { makeStyles } from "@material-ui/core/styles";
import {
  white,
  snowWhite,
  darkBluishGrey,
  pantoneRed,
  pantoneYellow,
  jungleGreen,
} from "../../consts/colors";
import { fontFamily } from "../../Assets/styles/commonStyles";

export const tableStyles = makeStyles({
  statusColumn: {
    textAlign: "center",
    borderRadius: "100px",
    letterSpacing: "2.5px",
    fontSize: "12px",
    background: white,
    fontWeight: "500",
  },
  overdue: {
    border: `2px solid ${pantoneRed}`,
  },
  notStarted: {
    border: `2px solid ${pantoneYellow}`,
    paddingTop: "3px",
  },
  inProgress: {
    border: `2px solid ${darkBluishGrey}`,
    paddingTop: "3px",
  },
  completed: {
    border: `2px solid ${jungleGreen}`,
    paddingTop: "2px",
  },
  timeLeftExclamation: {
    fontWeight: "bold",
    fontSize: "24px",
    color: pantoneRed,
    paddingLeft: "10px",
  },
  timeLeftNegative: {
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "20px",
    color: pantoneRed,
    "& > span": {
      display: "flex",
    },
  },
  timeLeft: {
    fontSize: "16px",
    lineHeight: "20px",
  },
  noResult: {
    fontFamily,
    fontSize: "20px",
    lineHeight: "23px",
    height: "8rem",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: snowWhite,
  },
  caughtUp: {
    marginTop: "3.5rem",
    marginBottom: "2rem !important",
  },
  timeLeftDesignClass: {
    paddingLeft: "2rem",
  },
  showReviewLoader: {
    position: "absolute",
    top: "35%",
    left: "50%",
  },
});
