import { makeStyles } from "@material-ui/core/styles";
import {
  mintCream,
  white,
  mehendiGrey,
  darkSlateGrey,
  pineGreen,
  dustyWhite,
} from "../../consts/colors";
import { fontFamily } from "../../Assets/styles/commonStyles";

export const useStyles = makeStyles({
  gridContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
  },
  leftMostGridItem: {
    paddingLeft: "0.5rem",
  },
  root: {
    backgroundColor: mintCream,
    height: "100%",
    fontFamily,
  },
  generalFont: {
    fontFamily,
    fontSize: "16px",
    lineHeight: "20px",
    wordBreak: "break-word",
  },
  paddingBox: {
    padding: "0.5rem",
  },
  baseRhyhtmContainer: {
    marginBottom: "1.25rem",
  },
  heading: {
    fontFamily,
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "20px",
    marginBottom: "0.5rem",
  },
  commonButton: {
    textTransform: "capitalize",
  },
  pillsContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  pill: {
    border: `1.5px dashed ${mehendiGrey}`,
    color: mehendiGrey,
    backgroundColor: white,
    fontFamily,
    fontSize: "14px",
    lineHeight: "16px",
    background: dustyWhite,
    borderRadius: "100px",
    padding: "0.5rem",
    textAlign: "center",
    marginRight: "0.5rem",
    marginTop: "0.5rem",
    "&:hover": {
      backgroundColor: white,
      cursor: "not-allowed",
    },
  },
  selectedpill: {
    fontFamily,
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "bold",
    background: darkSlateGrey,
    borderRadius: "100px",
    padding: "0.5rem",
    textAlign: "center",
    marginRight: "0.5rem",
    marginTop: "0.5rem",
    color: white,
    "&:hover": {
      background: darkSlateGrey,
      cursor: "not-allowed",
    },
  },
  secondaryObservationDiv: {
    marginBottom: "1rem",
    "& > p": {
      fontFamily,
    },
    "& > button": {
      fontFamily,
      fontSize: "14px",
      lineHeight: "16px",
    },
  },
  selectedItem: {
    marginBottom: "1rem",
  },
  notesAndButtonGrid: {
    padding: "0.5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  contianerBox: {
    minHeight: "375px",
  },
  notesContainer: {
    minHeight: "90px",
    marginBottom: "1rem",
  },
  reviewSubmittedByBox: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  reviewSubmittedByText: {
    fontFamily,
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "20px",
  },
  commonButtonStyles: {
    fontFamily,
    width: "49%",
    height: "40px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    "&:hover": {
      backgroundColor: pineGreen,
    },
  },
  acuityContainer: {
    marginBottom: "1.5rem",
  },
  closeButtonContainer: {
    display: "flex",
    justifyContent: "end",
  },
  submitButton: {
    backgroundColor: pineGreen,
    color: white,
  },
  avBlockMargin:{
    marginBottom:'0.5rem'
  }
});
