import { makeStyles } from "@material-ui/core/styles";
import { fontFamily } from "../../Assets/styles/commonStyles";
import { jungleGreen, white } from "../../consts/colors";

export const useStyles = makeStyles({
  container: {
    backgroundColor: white,
    overflow: "auto",
    height: "100vh",
    padding: "1rem",
    paddingTop: "0",
  },
  topMargin: {
    marginTop: "2rem",
  },
  divider: {
    border: `2px solid ${jungleGreen}`,
    background: jungleGreen,
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  submittingReviewLoader: {
    position: "absolute",
    top: "40%",
    left: "45%",
  },
  errorBoxContainer: {
    marginTop: "4rem",
    padding: "1.5rem",
  },
  errorMessageHeading: {
    fontFamily,
    textTransform: "capitalize",
    fontWeight: "600",
    fontSize: "22px",
  },
  errorMessageSubheading: {
    fontFamily,
    textTransform: "capitalize",
    fontWeight: "400",
    fontSize: "16px",
  },
});
