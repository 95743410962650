import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";

import BottomLineTitle from "../../Components/Shared/Typography/BottomLineTitle";
import MuiTypography from "../../Components/Shared/Typography/MuiTypography";
import ReportType from "../../Components/ReportType/ReportType";
import DateFilter from "../../Components/DateFilter/DateFilter";
import FilterBoxContainer from "../../Components/FilterBoxContainer/FilterBoxContainer";
import { useStyles } from "./EkgReportStyles";
import {
  getMembersData,
  isClearClickedStatus,
  exportReport,
  noReportForSelectedFilters,
} from "../../Store/EkgReport/EkgReportActions";
import { black } from "../../consts/colors";
import { fontFamily } from "../../Assets/styles/commonStyles";
import {
  CRP_SERVICE,
  REPORT_TYPE_MENU_OPTIONS,
} from "../../consts/ekgReportMenuOptionsConstants";
import { yearMonthDateFormat } from "../utils/changeTimeFormat";

let selectedService = "";
const onSelectService = (service) => {
  selectedService = service;
};

let selectedDateFilter = [];
const onDateSelection = (selectedDate) => {
  selectedDateFilter = [...selectedDate];
};

let selectedPracticesList = [];
const selectedPracticesHandler = (selectedItems) => {
  selectedPracticesList = [...selectedItems];
};

let selectedStatesList = [];
const selectedStatesHandler = (selectedItems) => {
  selectedStatesList = [...selectedItems];
};

let selectedPhysiciansList = [];
const selectedPhysiciansHandler = (selectedItems) => {
  selectedPhysiciansList = [...selectedItems];
};

let physiciansList = [];

let CRP_PRACTICE = [];

function EkgReport() {
  const [selectedReportType, setSelectedReportType] = useState("");
  const [practiceListLength, setPracticeListLength] = useState(0);
  const [isExportClicked, setIsExportClicked] = useState(false);

  const membersData = useSelector((state) => state.ekgReport.membersData);
  const isNoReportForSelectedFilters = useSelector(
    (state) => state.ekgReport.isNoReportForSelectedFilters
  );
  const isErrorGeneratingReport = useSelector(
    (state) => state.ekgReport.isErrorGeneratingReport
  );
  const team = useSelector((state) => state.user.team);

  const isUsRegion = localStorage.getItem('crpRegion') === 'us'

  const classes = useStyles();
  const dispatch = useDispatch();

  let CRP_STATES = [];
  let CRP_PHYSICIANS = [];
  let i = 0;
  let j = 0;

  const onSelectReportType = (reportType) => {
    setSelectedReportType(reportType);
  };

  const onSelectPracticeItem = (isEmpty) => {
    setPracticeListLength(isEmpty);
  };

  useEffect(() => {
    if (team) {
      CRP_PRACTICE.push({
        id: 0,
        practiceName: team.name,
      });
      dispatch(getMembersData(team.id));
    }
    return () => {
      CRP_PRACTICE = [];
    };
  }, []);

  if (membersData.length) {
    physiciansList = membersData.filter(
      (data) => data.member.permissions.isPhysician === true
    );
    let allStates = [];
    let states = new Set();

    for (let i = 0; i < physiciansList.length; i++) {
      //to get all regions (even duplicated ones)
      if(isUsRegion){
      allStates = [...allStates,  ...(physiciansList[i].member.regions || []) ];
      }
      else{
        //only including if country is not null
        if(physiciansList[i].member.country)
        allStates = [...allStates,  physiciansList[i].member.country  ]; 
      }
      //to get names of all physicians
      CRP_PHYSICIANS.push({
        id: j,
        physicianName: `${physiciansList[i].member.firstName} ${physiciansList[i].member.lastName}`,
        physicianId: `${physiciansList[i].member.id}`,
      });
      j++;
    }
    allStates.sort();
    //to get unique regions out of allStates array
    allStates.forEach((item) => states.add(item));

    //adding id key to each array item
    for (let item of states) {
      CRP_STATES.push({ id: i, stateName: item });
      i++;
    }
  }

  const clearAllHandler = () => {
    dispatch(isClearClickedStatus(true));
    dispatch(noReportForSelectedFilters(false));
    setIsExportClicked(false);
    setPracticeListLength(0);
  };

  const exportHandler = () => {
    setIsExportClicked(true);
    if (
      selectedService === "" ||
      selectedReportType === "" ||
      !selectedDateFilter[0] ||
      !selectedDateFilter[1] ||
      !selectedPracticesList.length
    ) {
      return;
    }
    const selectedFilters = {
      service: selectedService,
      reportType: selectedReportType,
      startDate: yearMonthDateFormat(selectedDateFilter[0]),
      endDate: yearMonthDateFormat(selectedDateFilter[1]),
      practice: selectedPracticesList,
      state: selectedStatesList,
      physician: selectedPhysiciansList,
    };
    dispatch(exportReport(selectedFilters));
    clearAllHandler();
  };

  return (
    <Box className={classes.container}>
      <Grid container>
        <Grid item className={classes.topMargin}>
          <MuiTypography
            fontSize="24px"
            fontFamily={fontFamily}
            lineHeight="28px"
            letterSpacing="0.25px"
            fontWeight={500}
            color={black}
          >
            {"Reports"}
          </MuiTypography>
          <BottomLineTitle />
        </Grid>
      </Grid>
      <Box>
        {/* <Box component="span" color={darkBluishGrey} borderBottom={1}>
          Dashboard
        </Box> */}
      </Box>
      <Paper className={classes.paperRoot}>
        <Box>
          <ReportType
            heading="Report Type:"
            FilterOneName="Service*"
            FilterTwoName="Report Type*"
            CRP_SERVICE={CRP_SERVICE}
            REPORT_TYPE_MENU_OPTIONS={REPORT_TYPE_MENU_OPTIONS}
            onSelectService={onSelectService}
            onSelectReportType={onSelectReportType}
            isExportClicked={isExportClicked}
          />
          <DateFilter
            onDateSelection={onDateSelection}
            selectedReportType={selectedReportType}
            isExportClicked={isExportClicked}
          />
          <FilterBoxContainer
            heading="Filter By:"
            FilterOneName="Practice*"
            FilterTwoName={isUsRegion?"State":'Country'}
            FilterThreeName="Physician"
            CRP_PRACTICE={CRP_PRACTICE}
            practiceListLength={practiceListLength}
            onSelectPracticeItem={onSelectPracticeItem}
            CRP_STATES={CRP_STATES}
            CRP_PHYSICIANS={CRP_PHYSICIANS}
            selectedPracticesHandler={selectedPracticesHandler}
            selectedStatesHandler={selectedStatesHandler}
            selectedPhysiciansHandler={selectedPhysiciansHandler}
            selectedReportType={selectedReportType}
            isExportClicked={isExportClicked}
            isUsRegion={isUsRegion}
          />
        </Box>
        {isNoReportForSelectedFilters && (
          <Box>
            <Typography className={classes.errorMessage}>
              There’s no data for the selected report. Please select different
              filters to export a report.
            </Typography>
          </Box>
        )}
        {isErrorGeneratingReport && (
          <Box>
            <Typography className={classes.errorMessage}>
              Something went wrong! Can’t Generate Report Right Now. Try Again
              Later!
            </Typography>
          </Box>
        )}
        <Box className={classes.buttonContainer}>
          <Button className={classes.clearButton} onClick={clearAllHandler}>
            CLEAR ALL
          </Button>
          <Button className={classes.exportButton} onClick={exportHandler}>
            EXPORT TO CSV
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default EkgReport;
