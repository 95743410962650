import styled from "styled-components";
import { jungleGreen } from "../../../consts/colors";

const BottomLineTitle = styled.span`
  width: 22px;
  height: 6px;
  border-radius: 2px;
  background-color: ${jungleGreen};
  display: block;
`;

export default BottomLineTitle;
