import * as types from "./types";
import { updateObject } from "../utility";

const initialState = {
  isClearClicked: false,
  membersData: [],
  isReportExported: false,
  isNoReportForSelectedFilters: false,
  isErrorGeneratingReport: false,
};

const isClearClickedStatus = (state, action) => {
  return updateObject(state, {
    isClearClicked: action.payload,
  });
};

const getMembersData = (state, action) => {
  return updateObject(state, {
    membersData: action.payload,
  });
};

const exportReport = (state, action) => {
  return updateObject(state, {
    isReportExported: action.payload,
  });
};

const noReportForSelectedFilters = (state, action) => {
  return updateObject(state, {
    isNoReportForSelectedFilters: action.payload,
  });
};

const isErrorGeneratingReport = (state, action) => {
  return updateObject(state, {
    isErrorGeneratingReport: action.payload,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GETCLEARCLICKEDSTATUS:
      return isClearClickedStatus(state, action);
    case types.GETMEMBERSDATA:
      return getMembersData(state, action);
    case types.SUBMIT_REPORT_BASED_ON_FILTER:
      return exportReport(state, action);
    case types.NO_REPORT_FOR_SELECTED_FILTERS:
      return noReportForSelectedFilters(state, action);
    case types.IS_ERROR_GENERATING_REPORT:
      return isErrorGeneratingReport(state, action);
    default:
      return state;
  }
};

export default reducer;
