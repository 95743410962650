import { makeStyles } from "@material-ui/core/styles";
import { black, darkSeaGrey } from "../../consts/colors";
import { fontFamily } from "../../Assets/styles/commonStyles";

export const useStyles = makeStyles({
  root: {
    fontFamily,
    padding: "0.25rem",
  },
  heading: {
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "20px",
  },
  row: {
    display: "flex",
    marginTop: "1rem",
    marginBottom: "1rem",
    "& > p": {
      fontFamily,
      letterSpacing: "-0.2px",
      wordBreak: "break-word",
    },
    "& > p::first-letter": {
      textTransform: "uppercase",
    },
  },
  headingColumn: {
    width: "30%",
    fontSize: "16px",
    lineHeight: "20px",
    color: black,
  },
  dataColumnBox: {
    width: "70%",
    "& > p": {
      fontFamily,
      letterSpacing: "-0.2px",
      wordBreak: "break-word",
      paddingRight: "0.5rem",
    },
    "& > p::first-letter": {
      textTransform: "uppercase",
    },
  },
  dataColumn: {
    fontSize: "16px",
    lineHeight: "20px",
    color: black,
    wordBreak: "break-word",
  },
  dataColumnWidth: {
    width: "70%",
  },
  detailsButton: {
    fontFamily,
    fontSize: "14px",
    color: darkSeaGrey,
    fontWeight: "bold",
    padding: "0",
    marginTop: "0.5rem",
  },
});
