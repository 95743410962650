import React, { useState } from "react";
import { Paper, Grid, Typography, Box, Button } from "@material-ui/core";
import { useStyles } from "./EkgPatientInformationStyles";
import { ageCalculateFromString } from "../../Containers/utils/ageCalculate";
import { getDateFromString } from "../../Containers/utils/changeTimeFormat";
import { cmToFeet } from "../../Containers/utils/cmToFeet";
import { LANGUAGE_MAPPER } from "../../consts/LanguageMapper";

function EkgPatientInformation(props) {
  const { dob, sex, weight, height, symptoms, patientnotes, language } = props;
  const [showFullSymptoms, setShowFullSymptoms] = useState(false);
  const [showFullPatientNotes, setShowFullPatientNotes] = useState(false);
  const classes = useStyles();

  const formattedDate = `${getDateFromString(dob)} (${ageCalculateFromString(
    dob
  )} years)`;

  const sumarizedSymptoms =
    symptoms.length > 100 ? symptoms.slice(0, 100) + "..." : symptoms;

  const sumarizedPatientNotes =
    patientnotes.length > 100
      ? patientnotes.slice(0, 100) + "..."
      : patientnotes;

  const notShowSymptomExpandButtonCondition =
    symptoms.length === sumarizedSymptoms.length;

  const notShowPatientExpandButtonCondition =
    patientnotes.length === sumarizedPatientNotes.length;

  const getLanguage = (code) => {
    let language;
    language = LANGUAGE_MAPPER.filter((item) => item.code === code);
    if (language.length) return language[0].name;
    return "English";
  };

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container>
        <Grid item className={classes.heading}>
          Patient Information:
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.row}>
              <Typography component="p" className={classes.headingColumn}>
                DoB (Age):
              </Typography>
              <Typography
                component="p"
                className={`${classes.dataColumn} ${classes.dataColumnWidth}`}
              >
                {dob ? formattedDate : "--"}
              </Typography>
            </Box>
            <Box className={classes.row}>
              <Typography component="p" className={classes.headingColumn}>
                Sex:
              </Typography>
              <Typography
                component="p"
                className={`${classes.dataColumn} ${classes.dataColumnWidth}`}
              >
                {sex ? sex : "--"}
              </Typography>
            </Box>
            <Box className={classes.row}>
              <Typography component="p" className={classes.headingColumn}>
                Weight:
              </Typography>
              <Typography
                component="p"
                className={`${classes.dataColumn} ${classes.dataColumnWidth}`}
              >
                {weight ? `${Math.floor(weight * 2.2046)} lbs` : "--"}
              </Typography>
            </Box>
            <Box className={classes.row}>
              <Typography component="p" className={classes.headingColumn}>
                Height:
              </Typography>
              <Typography
                component="p"
                className={`${classes.dataColumn} ${classes.dataColumnWidth}`}
              >
                {height ? cmToFeet(height) : "--"}
              </Typography>
            </Box>
            <Box className={classes.row}>
              <Typography component="p" className={classes.headingColumn}>
                Symptoms:
              </Typography>
              <Box className={classes.dataColumnBox}>
                <Typography component="p" className={classes.dataColumn}>
                  {!sumarizedSymptoms && "--"}
                  {!showFullSymptoms ? sumarizedSymptoms : ""}
                  {showFullSymptoms && symptoms}
                </Typography>
                {!showFullSymptoms && !notShowSymptomExpandButtonCondition && (
                  <Box>
                    <Button
                      className={classes.detailsButton}
                      onClick={() => setShowFullSymptoms(true)}
                    >
                      Read More
                    </Button>
                  </Box>
                )}
                {showFullSymptoms && !notShowSymptomExpandButtonCondition && (
                  <Box>
                    <Button
                      className={classes.detailsButton}
                      onClick={() => setShowFullSymptoms(false)}
                    >
                      Show Less
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className={classes.row}>
              <Typography component="p" className={classes.headingColumn}>
                Patient Notes:
              </Typography>
              <Box className={classes.dataColumnBox}>
                <Typography component="p" className={classes.dataColumn}>
                  {!sumarizedPatientNotes && "--"}
                  {!showFullPatientNotes ? sumarizedPatientNotes : ""}
                  {showFullPatientNotes && patientnotes}
                </Typography>
                {!showFullPatientNotes && !notShowPatientExpandButtonCondition && (
                  <Box>
                    <Button
                      className={classes.detailsButton}
                      onClick={() => setShowFullPatientNotes(true)}
                    >
                      Read More
                    </Button>
                  </Box>
                )}
                {showFullPatientNotes && !notShowPatientExpandButtonCondition && (
                  <Box component="p">
                    <Button
                      className={classes.detailsButton}
                      onClick={() => setShowFullPatientNotes(false)}
                    >
                      Show Less
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className={classes.row}>
              <Typography component="p" className={classes.headingColumn}>
                Language:
              </Typography>
              <Typography
                component="p"
                className={`${classes.dataColumn} ${classes.dataColumnWidth}`}
              >
                {getLanguage(language)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default EkgPatientInformation;
