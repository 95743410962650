import React from "react";
import { Paper, Box, Typography, Grid } from "@material-ui/core";

import { useStyles } from "./PatientGraphInfoStyles";
import {
  changeDateTimeFormat,
  getMeridiem,
} from "../../Containers/utils/changeTimeFormat";

function PatientGraphInfo(props) {
  const classes = useStyles();
  const { reportId, interval, interpretation, bpm, recordedAt } = props;

  return (
    <Paper elevation={0} className={classes.paperRoot}>
      <Grid container className={classes.containerGrid}>
        <Grid item xs={8}>
          {!interval ? (
            <Box className={classes.row}>
              <Typography component="p" className={classes.headingColumn}>
                &nbsp;
              </Typography>
              <Typography component="p" className={classes.dataColumn}>
                &nbsp;
              </Typography>
            </Box>
          ) : (
            ""
          )}
          <Box className={classes.row}>
            <Typography component="p" className={classes.headingColumn}>
              Heart Rate:
            </Typography>
            <Typography component="p" className={classes.dataColumn}>
              {bpm ? `${bpm} bpm` : "--"}
            </Typography>
          </Box>

          {interval ? (
            <Box className={classes.row}>
              <Typography component="p" className={classes.headingColumn}>
                RR Interval:
              </Typography>
              <Typography component="p" className={classes.dataColumn}>
                {interval ? interval : "--"}
              </Typography>
            </Box>
          ) : (
            ""
          )}
          <Box className={classes.row}>
            <Typography component="p" className={classes.headingColumn}>
              Interpretation:
            </Typography>
            <Typography
              component="p"
              className={`${classes.dataColumn} ${classes.interpretation}`}
            >
              {interpretation ? interpretation : "--"}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className={`${classes.rightRow} ${classes.emptyBox}`}>
            <Typography component="p"></Typography>
            <Typography component="p"></Typography>
          </Box>
          <Box className={classes.rightRow}>
            <Typography component="p" className={classes.rightHeadingColumn}>
              Report ID:
            </Typography>
            <Typography
              component="p"
              className={`${classes.rightHeadingColumn} ${classes.leftMargin}`}
            >
              {reportId ? reportId : "--"}
            </Typography>
          </Box>
          <Box className={classes.rightRow}>
            <Typography component="p" className={classes.rightHeadingColumn}>
              Date Recorded:
            </Typography>
            <Typography
              component="p"
              className={`${classes.rightHeadingColumn} ${classes.leftMargin}`}
            >
              {recordedAt
                ? `${changeDateTimeFormat(recordedAt)} ${getMeridiem(
                    recordedAt
                  )}`
                : "--"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
export default PatientGraphInfo;
