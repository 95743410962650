import moment from "moment";

export const changeDateTimeFormat = (rawTime) => {
  const utcDate = moment.utc(rawTime);
  const localDate = moment(utcDate).local();
  const month = (1 + localDate["_d"].getMonth()).toString().padStart(2, "0");
  const date = localDate["_d"].getDate().toString().padStart(2, "0");
  const year = localDate["_d"].getFullYear();
  const formattedDate = `${month}/${date}/${year}`;

  const time = localDate["_d"].toLocaleTimeString("en-IT");
  const splitter = time.split(" ");
  const actualTime = splitter[0].split(":");
  const formattedDateTime =
    formattedDate + " " + actualTime[0] + ":" + actualTime[1] + " ";
  return formattedDateTime;
};

export const changeDateFormat = (rawTime) => {
  const rawTimeDate = new Date(rawTime);
  const month = (1 + rawTimeDate.getMonth()).toString().padStart(2, "0");
  const date = rawTimeDate.getDate().toString().padStart(2, "0");
  const year = rawTimeDate.getFullYear();
  const formattedDate = `${month}/${date}/${year}`;
  return formattedDate;
};

export const yearMonthDateFormat = (rawTime) => {
  const rawTimeDate = new Date(rawTime);
  const month = (1 + rawTimeDate.getMonth()).toString().padStart(2, "0");
  const date = rawTimeDate.getDate().toString().padStart(2, "0");
  const year = rawTimeDate.getFullYear();
  const formattedDate = `${year}-${month}-${date}`;
  return formattedDate;
};

export const getMeridiem = (rawTime) => {
  const utcDate = moment.utc(rawTime);
  const localDate = moment(utcDate).local();
  const mer = localDate.format("a").toUpperCase();
  return mer;
};

export const getDateFromString = (dateString) => {
  const dateArr = dateString.split("-");
  const dateFields = {
    date: dateArr[2],
    month: dateArr[1],
    year: dateArr[0],
  };
  const formattedDate = `${dateFields.month}/${dateFields.date}/${dateFields.year}`;
  return formattedDate;
};
