import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  ClickAwayListener,
  Paper,
  Grid,
  IconButton,
  InputBase,
  InputAdornment,
  List,
  Typography,
  Tooltip,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import CancelIcon from "@material-ui/icons/Cancel";
import moment from "moment";

import MuiTypography from "../../Components/Shared/Typography/MuiTypography";
import {
  getSearchData,
  setSearchButtonClicked,
} from "../../Store/EkgSearch/EkgSearchActions";
import { useStyles } from "./EkgSearchStyles";
import { black } from "../../consts/colors";
import { fontFamily } from "../../Assets/styles/commonStyles";

const EkgSearch = (props) => {
  const [searchValue, setSearchvalue] = useState("");
  const [data, Setdata] = useState([]);
  const [display, setDisplay] = useState(false);

  const profile = useSelector((state) => state.user.profile);
  const tabState = useSelector((state) => state.ekgList.tabState);
  const dataResults = useSelector((state) => {
    return state.ekgSearch.searchresults;
  });
  const isSearchButtonClicked = useSelector((state) => {
    return state.ekgSearch.isSearchButtonClicked;
  });
  const isLoadingSearchResults = useSelector(
    (state) => state.ekgSearch.isLoadingSearchResults
  );
  const isErrorInSearch = useSelector((state) => state.ekgSearch.searchError);

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  let refreshaction;

  const isAdmin = profile.permissions.isAdmin;

  const openReview = (id) => {
    if (isAdmin && tabState === 1) {
      return;
    }
    if (isAdmin && tabState === 2) {
      history.push(`/admin/clinician/ecglist/review/${id}/completed`);
      return;
    }
    if (tabState === 2) {
      history.push(`/clinician/ecglist/review/${id}/completed`);
      return;
    }
    history.push(`/clinician/ecglist/review/${id}/incoming`);
  };

  useEffect(() => {
    if (searchValue.length > 1) {
      dispatch(getSearchData(searchValue, tabState));
      setDisplay(true);
    }
  }, [searchValue]);

  useEffect(() => {
    if (dataResults) {
      Setdata(dataResults);
    }
  }, [dataResults]);

  useLayoutEffect(() => {
    setDisplay(false);
    setSearchvalue("");
    Setdata([]);
  }, [tabState]);

  const onSearchClick = () => {
    if (searchValue.trim().length === 0) {
      return;
    }
    if (isErrorInSearch) {
      setSearchvalue("");
      setDisplay(false);
      return;
    }
    dispatch(setSearchButtonClicked(true));
    props.setIsEmptyTableData(false);
    props.clickHandler(searchValue, data.length);
    Setdata([]);
    setDisplay(false);
  };

  const refreshHandler = () => {
    dispatch(setSearchButtonClicked(false));
    setSearchvalue("");
    props.onRefreshClick();
  };

  const cancelSearch = () => {
    dispatch(setSearchButtonClicked(false));
    setSearchvalue("");
    props.clickHandler("", 0);
    Setdata([]);
    setDisplay(false);
    props.setIsEmptyTableData(true);
  };

  const handleClickAway = () => {
    setDisplay(false);
  };

  return (
    <React.Fragment>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.topbar}
      >
        <Grid item className={classes.searchBarContainer} xs={7}>
          <Box className={classes.inputContainer}>
            <InputBase
              className={classes.inputElement}
              id="search-bar"
              placeholder={
                tabState === 1
                  ? t("Search incoming list by Patient Name / Report ID")
                  : t("Search completed list by Patient Name / Report ID")
              }
              classes={{
                input: classes.inputBase,
              }}
              value={searchValue}
              onChange={(e) => setSearchvalue(e.target.value)}
              autoComplete="off"
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  onSearchClick();
                  event.target.blur();
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  {isSearchButtonClicked && (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={cancelSearch}
                    >
                      <CancelIcon className={classes.cancelIcon} />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
            {isLoadingSearchResults && display ? (
              <Paper elevation={6} className={classes.searchResultTray}>
                <li className={classes.list}>
                  <Typography
                    component="h4"
                    className={`${classes.searchResultTrayText} ${classes.noResultFound}`}
                  >
                    Searching...
                  </Typography>
                </li>
              </Paper>
            ) : (
              ""
            )}
            {!isLoadingSearchResults && searchValue.length > 1 && display && (
              <ClickAwayListener onClickAway={handleClickAway}>
                <Paper elevation={6} className={classes.searchResultTray}>
                  <List>
                    {data && data.length > 0 ? (
                      data.map((data, index) => {
                        return (
                          <li
                            className={`${classes.list} ${
                              isAdmin && tabState === 1 ? "" : classes.clickable
                            }`}
                            key={index}
                            onClick={(e) => openReview(data.id)}
                          >
                            <Tooltip
                              title={`${data.lastName}, ${data.firstName}`}
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Typography
                                component="h4"
                                className={`${classes.searchResultTrayText} ${classes.searchResultTrayName}`}
                              >
                                {`${data.lastName}, ${data.firstName}`}
                              </Typography>
                            </Tooltip>
                            <Typography
                              component="p"
                              className={classes.searchResultTrayText}
                            >
                              {data.reportid}
                            </Typography>
                          </li>
                        );
                      })
                    ) : !isLoadingSearchResults ? (
                      <li className={classes.list}>
                        <Typography
                          component="h4"
                          className={`${classes.searchResultTrayText} ${classes.noResultFound}`}
                        >
                          No Results Found
                        </Typography>
                      </li>
                    ) : (
                      ""
                    )}
                  </List>
                </Paper>
              </ClickAwayListener>
            )}
          </Box>
          <Grid item>
            <Button
              onClick={() => onSearchClick()}
              className={`${classes.searchButton} 
              ${isErrorInSearch ? classes.errorInSearchButton : ""}
              `}
            >
              SEARCH
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Grid item container alignItems="center" direction="row">
            <Grid item>
              <MuiTypography
                fontSize="14px"
                fontFamily={fontFamily}
                lineHeight="20px"
                letterSpacing="-0.2px"
                color={black}
              >
                {t("Last Refreshed")}
              </MuiTypography>
            </Grid>
            <Grid item>
              <MuiTypography
                fontSize="14px"
                fontFamily={fontFamily}
                lineHeight="20px"
                letterSpacing="-0.2px"
                color={black}
              >
                &nbsp;{moment(refreshaction).format("hh:mm A")}
              </MuiTypography>
            </Grid>
            <Grid item>
              <IconButton onClick={refreshHandler}>
                <RefreshIcon className={classes.refreshIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.searchTabSeperator} />
    </React.Fragment>
  );
};

export default EkgSearch;
