import { createTheme } from "@material-ui/core/styles";
import {
  zompGreen,
  darkBluishGrey,
  lightGrey,
  lightAquaGreen,
  halfBlack,
  quarterBlack,
} from "../../consts/colors";
import { fontFamily } from "../../Assets/styles/commonStyles";

export const materialTheme = createTheme({
  overrides: {
    MuiPickersCalendarWeekDayLabel: {
      fontFamily,
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: zompGreen,
        },
        "&$error": {
          color: halfBlack,
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 13px) scale(1)",
      },
    },
    MuiOutlinedInput: {
      root: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: zompGreen,
        },
        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: quarterBlack,
        },
      },
      input: {
        fontFamily,
        padding: "10.5px 10px",
        "&:focus": {
          backgroundColor: lightAquaGreen,
        },
        "&:selected": {
          backgroundColor: lightAquaGreen,
        },
        "&:hover": {
          backgroundColor: lightAquaGreen,
        },
      },
    },
    MuiPickersDay: {
      root: {
        fontFamily,
        fontSize: "14px",
        lineHeight: "24px",
        backgroundColor: zompGreen,
        "&$selected": {
          backgroundColor: zompGreen,
          "&:hover": {
            backgroundColor: zompGreen,
          },
        },
        // "&$disabled": {},
        "&:focus": {
          "&$selected": {
            backgroundColor: zompGreen,
          },
        },
      },
      today: {
        backgroundColor: zompGreen,
      },
    },
    MuiPickersDateRangeDay: {
      dayInsideRangeInterval: {
        color: darkBluishGrey,
      },
      rangeIntervalDayHighlight: {
        backgroundColor: lightGrey,
      },
    },
    MuiFormHelperText: {
      root: {
        "&$error": {
          color: halfBlack,
        },
      },
    },
  },
});
