export const CRP_SERVICE = [
  {
    id: "1",
    serviceName: "Clinician Review",
  },
];

export const REPORT_TYPE_MENU_OPTIONS = [
  {
    id: "1",
    reportType: "Completed Reviews",
  },
  {
    id: "2",
    reportType: "Current Reviews in Queue",
  },
  {
    id: "3",
    reportType: "Current Overdue Reviews",
  },
  {
    id: "4",
    reportType: "Reviews Returned Late",
  },
];
