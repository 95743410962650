import { makeStyles } from "@material-ui/core/styles";
import { slateGrey, pantoneRed } from "../../consts/colors";
import { fontFamily } from "../../Assets/styles/commonStyles";

export const useStyles = makeStyles({
  headingContainer: {
    marginBottom: "1rem",
  },
  heading: {
    fontFamily,
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.25px",
  },
  subHeading: {
    fontFamily,
    fontSize: "14px",
    lineHeight: "16px",
    color: slateGrey,
  },
  errorSubHeading: {
    color: pantoneRed,
  },
  errorMessage: {
    fontFamily,
    color: pantoneRed,
    marginTop: "0.25rem",
    fontSize: "14px",
  },
});
