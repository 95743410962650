import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Redirect } from "react-router-dom";
import axios from "axios";
import { Grid, Box, Typography } from "@material-ui/core";

import BottomLineTitle from "../../Components/Shared/Typography/BottomLineTitle";
import MuiTypography from "../../Components/Shared/Typography/MuiTypography";
import EkgPatientInformation from "../../Components/EkgPatientInformation/EkgPatientInformation";
import ClinicianInteraction from "../../Components/ClinicianInteraction/ClinicianInteraction";
import ClinicianInteractionInfo from "../../Components/ClinicianInteractionInfo/Clinicianinteractioninfo";
import PatientGraphInfo from "../../Components/PatientGraphInfo/PateintGraphInfo";
import EkgCharts from "../../Components/chart/EkgCharts/EkgCharts";
import EcgLoader from "../../Components/Shared/EcgLoader";
import { useStyles } from "./EkgReviewStyles";
import {
  getPatientData,
  emptyPatientData,
} from "../../Store/EkgReview/EkgReviewActions";
import { getEKGAlgorithmDeterminationDisplayText } from "../../consts/recordingMaps";
import { black } from "../../consts/colors";
import { fontFamily } from "../../Assets/styles/commonStyles";
import { CLINICIAN_ECGLIST, ADMIN_ECGLIST } from "../../Auth/Routes";

const EkgReview = () => {
  const classes = useStyles();
  const { id, completed } = useParams();

  const dispatch = useDispatch();

  const profile = useSelector((state) => state.user.profile);
  const patientData = useSelector((state) => state.ekgReview.patientInfo);
  const isLoadingPatientData = useSelector(
    (state) => state.ekgReview.isLoadingPatientData
  );
  const recordingSample = useSelector(
    (state) => state.ekgReview.recordingSample
  );
  const reviewDetail = useSelector((state) => state.ekgReview.reviewDetail);
  const errorGettingPatientData = useSelector(
    (state) => state.ekgReview.errorGettingPatientData
  );
  const isSubmittingReview = useSelector(
    (state) => state.ekgReview.isSubmittingReview
  );

  const isPhysician = profile.permissions.isPhysician;
  const isAdmin = profile.permissions.isAdmin;

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    dispatch(getPatientData(id, completed, source.token));
    return () => {
      source.cancel();
      dispatch(emptyPatientData());
    };
  }, [dispatch, id]);

  return (
    <React.Fragment>
      {isLoadingPatientData && (
        <Box className={`${classes.container} ${classes.loadingContainer}`}>
          <Box>
            <Typography>Please Wait, Loading Patient Details...</Typography>
          </Box>
        </Box>
      )}
      {Object.keys(patientData).length !== 0 && !isLoadingPatientData && (
        <Box className={classes.container}>
          {isSubmittingReview && (
            <Box className={classes.submittingReviewLoader}>
              <EcgLoader />
            </Box>
          )}
          <Grid container>
            <Grid item className={classes.topMargin}>
              <Box style={{ marginBottom: "0.1rem" }}>
                <MuiTypography
                  fontSize="24px"
                  fontFamily={fontFamily}
                  lineHeight="28px"
                  letterSpacing="0.25px"
                  fontWeight={500}
                  color={black}
                  textTransform="capitalize"
                >
                  {`${patientData.lastName}, ${patientData.firstName}`}
                </MuiTypography>
              </Box>
              <BottomLineTitle />
            </Grid>
            <Grid item xs={12} className={classes.topMargin}>
              <Box className={classes.divider}></Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              <EkgPatientInformation
                dob={patientData.dob}
                sex={patientData.sex === "M" ? "Male" : "Female"}
                weight={patientData.weight}
                height={patientData.height}
                language={patientData.language}
                symptoms={
                  patientData.symptoms
                    ? patientData.symptoms
                        .reduce((str, ele) => (str += ele + ", "), "")
                        .slice(0, -2)
                    : ""
                }
                patientnotes={
                  patientData.patientNotes ? patientData.patientNotes : ""
                }
              />
            </Grid>
            <Grid item xs={7}>
              {reviewDetail && reviewDetail?.status === "COMPLETED" ? (
                <ClinicianInteractionInfo
                  patientId={id}
                  reviewDetail={reviewDetail}
                />
              ) : reviewDetail &&
                reviewDetail?.status !== "COMPLETED" &&
                isPhysician ? (
                <ClinicianInteraction
                  patientId={id}
                  reportId={patientData.reportId}
                />
              ) : (
                <Redirect to={ADMIN_ECGLIST} replace={true} />
              )}
            </Grid>
          </Grid>
          <PatientGraphInfo
            reportId={patientData.reportId}
            interval={patientData.interval}
            interpretation={getEKGAlgorithmDeterminationDisplayText(
              patientData.interpretation,
              patientData.algorithmPackage,
              +patientData.heartRate
            )}
            bpm={patientData.heartRate}
            recordedAt={patientData.recordedAt}
          />
          {recordingSample && (
            <EkgCharts
              recording={recordingSample}
              is6l={Object.keys(recordingSample.samples).length === 6}
              duration={patientData?.duration}
            />
          )}
        </Box>
      )}
      {errorGettingPatientData && isPhysician && (
        <Redirect to={CLINICIAN_ECGLIST} replace={true} />
      )}
      {errorGettingPatientData && isAdmin && (
        <Redirect to={ADMIN_ECGLIST} replace={true} />
      )}
    </React.Fragment>
  );
};

export default EkgReview;
