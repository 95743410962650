import * as types from "./types";
import { updateObject } from "../utility";

const initialState = {
  isFetchingResourceContent: false,
  resourcesContent: null,
  isErrorGettingResourceData: false,
};

const isFetchingResourceData = (state, action) => {
  return updateObject(state, {
    isFetchingResourceContent: action.payload,
  });
};

const getResourceData = (state, action) => {
  return updateObject(state, {
    resourcesContent: action.payload,
  });
};

const isErrorFetchingResourceData = (state, action) => {
  return updateObject(state, {
    isErrorGettingResourceData: action.payload,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_RESOURCE_CONTENT:
      return getResourceData(state, action);
    case types.IS_FETCHING_RESOURCE_DATA:
      return isFetchingResourceData(state, action);
    case types.IS_ERROR_GETTING_RESOURCE_DATA:
      return isErrorFetchingResourceData(state, action);
    default:
      return state;
  }
};

export default reducer;
