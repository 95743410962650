import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Typography, Grid, Tabs, Tab } from "@material-ui/core";

import MuiTypography from "../../Components/Shared/Typography/MuiTypography";
import BottomLineTitle from "../../Components/Shared/Typography/BottomLineTitle";
import EkgSearch from "../EkgSearch/EkgSearch";
import EkgListTable from "../EkgListTable/EkgListTable";
import TabPanel from "../../Components/TabPanel/TabPanel";
import Toast from "../../Components/Toast/Toast";
import { setTabState } from "../../Store/EkgList/EkgListActions";
import {
  setSearchButtonClicked,
  errorRetrievingSearchList,
} from "../../Store/EkgSearch/EkgSearchActions";
import { showToastHandler } from "../../Store/EkgReview/EkgReviewActions";
import { useStyles } from "./EkgListStyles";
import { black } from "../../consts/colors";
import { fontFamily } from "../../Assets/styles/commonStyles";

const EkgList = () => {
  const [enteredSearchString, setEnteredSearchString] = useState("");
  const [updatelist, setUpdatelist] = useState(1);
  const [isEmptyTableData, setIsEmptyTableData] = useState(false);

  const tabState = useSelector((state) => state.ekgList.tabState);
  const [value, setValue] = useState(tabState - 1);
  const isErrorInSearch = useSelector((state) => state.ekgSearch.searchError);
  const isLoadingData = useSelector(
    (state) => state.ekgListTable.isLoadingData
  );
  const isShowToastMessage = useSelector((state) => state.ekgReview.showToast);
  const reportId = useSelector((state) => state.ekgReview.reportId);
  const notstartedAndOverdueCount = useSelector(
    (state) => state.ekgListTable.notstartedAndOverdueCount
  );

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setSearchButtonClicked(false));
      dispatch(errorRetrievingSearchList(false));
    };
  }, []);

  const onRefreshClick = () => {
    setUpdatelist((prev) => prev + 1);
    setEnteredSearchString("");
  };

  const handleChange = (event, newValue) => {
    if (isLoadingData) {
      return;
    }
    setValue(newValue);
  };

  const onClickHandler = (enteredSearchString, count) => {
    setEnteredSearchString(enteredSearchString);
  };

  const incomingClickHandler = () => {
    if (isLoadingData) {
      return;
    }
    dispatch(setTabState(1));
    dispatch(setSearchButtonClicked(false));
    setEnteredSearchString("");
    setUpdatelist(1);
    setIsEmptyTableData(false);
  };

  const completedClickHandler = () => {
    if (isLoadingData) {
      return;
    }
    dispatch(setTabState(2));
    setEnteredSearchString("");
    setUpdatelist(1);
    dispatch(setSearchButtonClicked(false));
    setIsEmptyTableData(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(showToastHandler(false));
  };

  const handleSearchErrorToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(errorRetrievingSearchList(false));
  };

  const incomingTotatCountBadge = (
    <Box className={classes.incomingAndTotalBadgeContainer}>
      <Typography
        component="span"
        className={`${classes.tabHeading} ${
          tabState === 1 ? classes.tabSelected : classes.tabNotSelected
        }`}
      >
        INCOMING
      </Typography>
      {tabState === 1 && notstartedAndOverdueCount !== 0 ? (
        <Box className={classes.totalCountBadgeContainer}>
          <Typography component="p" className={classes.totalCountBadge}>
            {notstartedAndOverdueCount}
          </Typography>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
  const completedTotatCountBadge = (
    <Box>
      <Typography
        component="span"
        className={`${classes.tabHeading} ${
          tabState === 2 ? classes.tabSelected : classes.tabNotSelected
        }`}
      >
        COMPLETED
      </Typography>
    </Box>
  );

  return (
    <Box className={classes.container}>
      <Grid>
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item style={{ marginTop: "2.5rem" }}>
            <MuiTypography
              fontSize="24px"
              fontFamily={fontFamily}
              lineHeight="28px"
              letterSpacing="0.25px"
              fontWeight={500}
              color={black}
            >
              {t("EKG WorkList")}
            </MuiTypography>
            <BottomLineTitle />
          </Grid>
        </Grid>
        <Box>
          {/* <Box component="span" color={darkBluishGrey} borderBottom={1}>
            Dashboard
          </Box> */}
          {isShowToastMessage && (
            <Box className={classes.toastContainer}>
              <Toast
                open={isShowToastMessage}
                closeHandler={handleClose}
                alertMessage={`Report ${reportId} has been successfully submitted.`}
                severity="success"
              />
            </Box>
          )}
          {isErrorInSearch && (
            <Box className={classes.toastContainer}>
              <Toast
                open={isErrorInSearch}
                closeHandler={handleSearchErrorToastClose}
                alertMessage={`Search Results are not available at the moment!`}
                severity="error"
              />
            </Box>
          )}
          <Box m={2} />
          <Box m={2} />
          <Tabs
            classes={{
              root: classes.customTabRoot,
              indicator: classes.customTabIndicator,
            }}
            value={value}
            onChange={handleChange}
            centered
            variant="fullWidth"
          >
            <Tab
              label={incomingTotatCountBadge}
              onClick={incomingClickHandler}
              disabled={isLoadingData && tabState !== 1}
            />
            <Tab
              label={completedTotatCountBadge}
              onClick={completedClickHandler}
              disabled={isLoadingData && tabState !== 2}
            />
          </Tabs>
          <EkgSearch
            setIsEmptyTableData={setIsEmptyTableData}
            clickHandler={onClickHandler}
            onRefreshClick={onRefreshClick}
          />
          <TabPanel value={value} index={0}>
            <EkgListTable
              isEmptyTableData={isEmptyTableData}
              enteredSearchString={enteredSearchString}
              refresh={updatelist}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <EkgListTable
              isEmptyTableData={isEmptyTableData}
              enteredSearchString={enteredSearchString}
              refresh={updatelist}
            />
          </TabPanel>
        </Box>
      </Grid>
    </Box>
  );
};

export default EkgList;
