import React from "react";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useStyles } from "./ToastStyles";
import CheckIcon from "@material-ui/icons/Check";
import { darkBluishGrey, lightGreen } from "../../consts/colors";
import { fontFamily } from "../../Assets/styles/commonStyles";

//Usage :
// Expects 3 props =>
// open: whether to show Toast message or not (true/false)
// closeHandler: function which will set state passed to open to false
// alertMessage: Toast Message

/* 
Note : to prevent closing of Snackbar on clickaway event, please add below condition in your closeHandler Function =>
const closeHandler = (event, reason) => {
  if(reason === 'clickaway){
    return;
  }
  //below your closing code 
}
*/

function Toast(props) {
  const classes = useStyles();
  const { open, closeHandler, alertMessage, severity } = props;

  return (
    <Snackbar
      open={open}
      className={classes.snackBarStyle}
      autoHideDuration={8000}
      onClose={closeHandler}
    >
      <Alert
        onClose={closeHandler}
        severity={severity !== "" ? severity : ""}
        iconMapping={{
          success: (
            <CheckIcon className={classes.checkIconCustom} fontSize="inherit" />
          ),
        }}
        className={`${
          severity === "success"
            ? classes.toastSuccessMessage
            : classes.toastMessage
        }`}
        sx={{
          width: "100%",
          fontFamily,
          fontWeight: "normal",
          fontSize: "16px",
          lineHeight: "20px",
          backgroundColor: { lightGreen },
          color: darkBluishGrey,
          letterSpacing: "-0.2px",
        }}
      >
        {alertMessage}
      </Alert>
    </Snackbar>
  );
}

export default Toast;
