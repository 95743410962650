import { makeStyles } from "@material-ui/core/styles";
import { mehendiGrey, white, darkBluishGrey } from "../../consts/colors";
import { fontFamily } from "../../Assets/styles/commonStyles";

export const useStyles = makeStyles({
  selectBoxContainer: {
    height: "180px",
    borderRadius: "4px",
    backgroundColor: white,
  },
  selectBoxBorder: {
    padding: "0.5rem",
    border: `1px solid ${mehendiGrey}`,
    boxSizing: "border-box",
  },
  selectedItemsContainer: {
    padding: "0.5rem",
    height: "128px",
    overflow: "auto",
  },
  selectedStatesContainer: {
    display: "flex",
    flexWrap: "wrap",
    height: "125px",
    padding: "0.5rem",
    overflow: "auto",
  },
  pill: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${darkBluishGrey}`,
    boxSizing: "border-box",
    borderRadius: "100px",
    backgroundColor: white,
    height: "32px",
    marginTop: "6px",
  },
  smallPill: {
    width: "30%",
    marginLeft: "0.5rem",
  },
  selectedItem: {
    padding: "6px",
    fontFamily,
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "-0.2px",
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  cancelIcon: {
    color: darkBluishGrey,
    height: "20px",
    width: "20px",
  },
});
