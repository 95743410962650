import React from "react";
import { Box, Link, Typography } from "@material-ui/core";
import { useStyles } from "./ResourceContentStyles";

/* Usage : expects heading, description and file link */

function ResourceContent(props) {
  const { heading, desc, file } = props;
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Link
        href={file}
        target="_blank"
        underline="always"
        className={classes.heading}
        // download
        // rel="noopener noreferrer"
        //  href={props.file}
        // href='https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'
        // href='https://us-kardia-staging.alivecor.com/i/v1/recordings/ejlhqlzx22zpoo4mw4zsj9xyy.pdf'
        // rel='noopener noreferrer'
      >
        {heading}
      </Link>
      <Typography className={classes.description}>{desc}</Typography>
    </Box>
  );
}

export default ResourceContent;
