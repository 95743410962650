import * as types from "./types";
import { updateObject } from "../utility";

const initialState = {
  profile: null,
  team: null,
};

const getUserSuccess = (state, action) => {
  if (action.payload === "logOut") {
    return updateObject(state, {
      profile: null,
      team: null,
    });
  } else
    return updateObject(state, {
      profile: action.payload.profile,
      team: action.payload.teams[0],
    });
};

const getUserFail = (state, action) => {
  return updateObject(state, null);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_SUCCESS:
      return getUserSuccess(state, action);
    case types.GET_USER_FAIL:
      return getUserFail(state, action);
    default:
      return state;
  }
};

export default reducer;
