import * as types from "./types";
import { getToken } from "../../Auth/AuthUtilities";
import { CrpAPI } from "../../Axios/axios";

export const emptyDataRedux = () => {
  return {
    type: types.RESET_REDUX_DATA,
  };
};

const errorRetrievingIncomingList = (status) => {
  return {
    type: types.ERRORRETRIEVINGINCOMINGLIST,
    payload: status,
  };
};

const errorRetrievingCompletedList = (status) => {
  return {
    type: types.ERRORRETRIEVINGCOMPLETEDLIST,
    payload: status,
  };
};

export const isFetching = (status) => {
  return {
    type: types.ISFETCHING,
    payload: status,
  };
};

export const getOverdueAndNotStartedCount = () => {
  return (dispatch) => {
    CrpAPI.get("count", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: types.GET_OVERDUE_AND_NOTSTARTED_COUNT,
            payload: res.data.data.count,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

export const getAvailabData = (
  limit,
  page = "0",
  timereq,
  searchterm,
  status,
  timeSubmitted = true
) => {
  let timeRequestedKey;
  let timeSubmitKey;
  let search = searchterm ? searchterm : "";

  if (timereq === true) {
    timeRequestedKey = "asc";
  } else {
    timeRequestedKey = "desc";
  }

  let APIStatus = "";
  if (status === 1) {
    APIStatus = "INCOMMING";
    return (dispatch) => {
      dispatch(getOverdueAndNotStartedCount());
      dispatch(isFetching(true));
      CrpAPI.get(
        `worklist?limit=${limit}&offset=${page}&search=${search}&timeRequest=${timeRequestedKey}&status=${APIStatus}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
        .then((res) => {
          if (res.status === 200) {
            dispatch(isFetching(false));
            dispatch({
              type: types.GETDATA_SUCCESS,
              payload: res.data.data,
            });
            dispatch(errorRetrievingIncomingList(false));
          }
        })
        .catch((err) => {
          dispatch(isFetching(false));
          dispatch(errorRetrievingIncomingList(true));
        });
    };
  }
  if (status === 2) {
    APIStatus = "COMPLETED";
    if (timeSubmitted === true) {
      timeSubmitKey = "asc";
    } else {
      timeSubmitKey = "desc";
    }
    return (dispatch) => {
      dispatch(isFetching(true));
      CrpAPI.get(
        `worklist?limit=${limit}&offset=${page}&search=${search}&timeRequest=${timeRequestedKey}&status=${APIStatus}&timeSubmit=${timeSubmitKey}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
        .then((res) => {
          dispatch(isFetching(false));
          if (res.status === 200) {
            dispatch({
              type: types.GETDATA_SUCCESS,
              payload: res.data.data,
            });
            dispatch(errorRetrievingCompletedList(false));
          }
        })
        .catch((err) => {
          dispatch(isFetching(false));
          dispatch(errorRetrievingCompletedList(true));
        });
    };
  }
};

export const checkReviewStatus = (id) => {
  return (dispatch) => {
    CrpAPI.get(`checkStatus/${id}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: types.ISREVIEWINPROGRESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

export const resetIsOpenReviewClicked = () => {
  return {
    type: types.RESETISOPENREVIEWCLICKED,
  };
};
