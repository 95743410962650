import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Typography } from "@material-ui/core";

import SelectBox from "../SelectBox/SelectBox";
import { useStyles } from "./ReportTypeStyles";
import { isClearClickedStatus } from "../../Store/EkgReport/EkgReportActions";

/*  heading="Report Type:"
    FilterOneName="Service"
    FilterTwoName="Report Type" */
function ReportType(props) {
  const {
    heading,
    FilterOneName,
    FilterTwoName,
    CRP_SERVICE,
    REPORT_TYPE_MENU_OPTIONS,
    onSelectService,
    onSelectReportType,
    isExportClicked,
  } = props;

  const [selectedServiceType, setSelectedServiceType] = useState("");
  const [selectedReportType, setSelectedReportType] = useState("");

  const isClearClicked = useSelector((state) => state.ekgReport.isClearClicked);

  const dispatch = useDispatch();
  const classes = useStyles();

  const selectServiceTypeHandler = (selectedValue) => {
    setSelectedServiceType(selectedValue);
  };

  const selectReportTypeHandler = (selectedValue) => {
    setSelectedReportType(selectedValue);
  };

  useEffect(() => {
    setSelectedServiceType("");
    setSelectedReportType("");
    onSelectService("");
    onSelectReportType("");
    dispatch(isClearClickedStatus(false));
  }, [isClearClicked]);

  useEffect(() => {
    if (selectedServiceType === "" && selectedReportType === "") {
      return;
    }
    if (selectedServiceType) {
      onSelectService(CRP_SERVICE[+selectedServiceType - 1].serviceName);
    }
    if (selectedReportType) {
      onSelectReportType(
        REPORT_TYPE_MENU_OPTIONS[+selectedReportType - 1].reportType
      );
    }
  }, [selectedServiceType, selectedReportType]);

  return (
    <Box>
      <Box className={classes.headingContainer}>
        <Typography className={classes.heading}>{heading}</Typography>
      </Box>
      <Grid container style={{ display: "flex" }}>
        <Grid item xs={2}>
          <Typography
            className={`${classes.subHeading} ${
              isExportClicked && !selectedServiceType
                ? classes.errorSubHeading
                : ""
            }`}
          >
            {FilterOneName}
          </Typography>
          <SelectBox
            value={selectedServiceType}
            onSelect={selectServiceTypeHandler}
            menuOptionsData={CRP_SERVICE}
            placeholder="Select Service"
            keyName="serviceName"
          />
          {/* {isExportClicked && selectedServiceType === "" && (
            <Typography className={classes.errorMessage}>
              Please Select Service
            </Typography>
          )} */}
        </Grid>
        <Grid item xs={2} style={{ marginLeft: "1.5rem" }}>
          <Typography
            className={`${classes.subHeading} ${
              isExportClicked && !selectedReportType
                ? classes.errorSubHeading
                : ""
            }`}
          >
            {FilterTwoName}
          </Typography>
          <SelectBox
            value={selectedReportType}
            onSelect={selectReportTypeHandler}
            menuOptionsData={REPORT_TYPE_MENU_OPTIONS}
            placeholder="Select Report Type"
            keyName="reportType"
          />
          {/* {isExportClicked && selectedReportType === "" && (
            <Typography className={classes.errorMessage}>
              Please Select Report Type
            </Typography>
          )} */}
        </Grid>
      </Grid>
    </Box>
  );
}

export default ReportType;
