import React from 'react';
//import ContentHeader from 'legacy/containers/ContentHeader';
import styles from './AccessDeniedContent.scss';
import { useTranslation } from "react-i18next";

function AccessDeniedContent(props) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {/* <ContentHeader
        text={props.title || ''}
        showAddPatient={false}
        showLastRefresh={false}
      /> */}
      <div
        className={styles.contentWrapper}
        style={{
          height: '100%',
        }}
      >
        <h1 className={styles.accessErrorHeadline}>Access Denied</h1>
        <p className={styles.accessErrorDesc}>
        {t("Sorry, your practice does not have access rights to this feature")} 
        </p>
        <p className={styles.accessErrorInstr}>
        {t("Please contact your administrator for assistance.")} 
        </p>
      </div>
    </React.Fragment>
  );
}

export default AccessDeniedContent;
