import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Paper,
  Grid,
  Typography,
  Box,
  Button,
  TextareaAutosize,
  FormControl,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import WarningIcon from "../../Assets/images/Vector.png";
import CustomModal from "../Modals/CustomModal";
import SelectBox from "../SelectBox/SelectBox";
import {
  getBaseRythmData,
  getAcuity,
  reviewSubmit,
  updateReportStatus,
  submitPatientReportId,
  setDefaultSubmitStatus,
  setReviewErrorMessage,
} from "../../Store/EkgReview/EkgReviewActions";
import { acuityMapper } from "../../consts/acuityMapper";
import { CLINICIAN_ECGLIST } from "../../Auth/Routes";
import { RAILS_409_CODE } from "../../consts/errorCodes";
import { useStyles, GreenCheckbox } from "./ClinicianInteractionStyles";

function ClinicianInteraction(props) {
  const classes = useStyles();

  const [options, setOptions] = useState([]);
  const [optionsAcuity, setOptionsAcuity] = useState([]);
  const [baseRhythm, setBaseRhythm] = useState("");
  const [acuity, setAcuity] = useState("");
  const [isCancelClicked, setIsCancelClicked] = useState(false);
  const [isNetworkError, setIsNetworkError] = useState(false);
  const [isSubmittingReviewFailed, setIsSubmittingReviewFailed] =
    useState(false);
  const [pvc, setPvc] = useState("");
  const [pav, setPav] = useState("");
  const [qac, setQac] = useState("");
  const [isSubmit, setIsSubmit] = useState(0);
  const [textNotes, setTextNotes] = useState("");
  const [showRedirectToWorkListModal, setShowRedirectToWorkListModal] =
    useState(false);
  const [selectedAvBlock, setSelectedAvBlock] = useState(false);
  const [avblocks, setAvBlocks] = useState({
    none: false,
    seconddegree: false,
    firstdegree: false,
    thirddegree: false,
  });

  const profile = useSelector((state) => state.user.profile);
  const baseRhythmData = useSelector((state) => state.ekgReview.baseRhythm);
  const acuityData = useSelector((state) => state.ekgReview.acuity);
  const isSuccessSubmittingReview = useSelector(
    (state) => state.ekgReview.isReviewSubmitted
  );
  const {
    isSubmittingReview,
    errorGettingBaseRhythm,
    errorGettingAcuity,
    reviewErrorMessage,
  } = useSelector((state) => state.ekgReview);
  const { none, seconddegree, firstdegree, thirddegree } = avblocks;

  const errorState =
    (!baseRhythm || !acuity || !pvc || !qac || !qac || !textNotes) && isSubmit;

  const dispatch = useDispatch();
  const history = useHistory();

  const isSuccessRef = useRef();
  isSuccessRef.current = isSuccessSubmittingReview;
  const isRails409ErrorRef = useRef();
  isRails409ErrorRef.current = reviewErrorMessage;

  useEffect(() => {
    dispatch(getBaseRythmData());
    dispatch(getAcuity());
    dispatch(updateReportStatus(props.patientId, "inProgress"));
    dispatch(setReviewErrorMessage(""));
  }, []);

  useEffect(() => {
    if (baseRhythmData) {
      setOptions(baseRhythmData);
    }
    if (acuityData) {
      setOptionsAcuity(acuityData);
    }
  }, [baseRhythmData, acuityData]);

  useEffect(() => {
    if (baseRhythm) {
      const selectedRhythm = options.find((o) => o.id === baseRhythm);
      const acuity = optionsAcuity.find(
        (item) => item.id === selectedRhythm.acuityId
      );
      setAcuity(acuity.id);
      setTextNotes(selectedRhythm.note ? selectedRhythm.note : "");
    }
  }, [baseRhythm]);

  useEffect(() => {
    if (isSuccessSubmittingReview) {
      history.replace(CLINICIAN_ECGLIST);
      return;
    }
    if (
      isSubmit &&
      (baseRhythm || pav || pvc || qac || textNotes || acuity) &&
      !isNetworkError
    ) {
      if (reviewErrorMessage?.includes(RAILS_409_CODE)) {
        setShowRedirectToWorkListModal(true);
        return;
      }
      setIsSubmittingReviewFailed(true);
    }
  }, [isSuccessSubmittingReview]);

  useEffect(() => {
    setShowRedirectToWorkListModal(false);
    return () => {
      if (isSuccessRef.current) {
        dispatch(setDefaultSubmitStatus());
        return;
      }
      if (isRails409ErrorRef.current?.includes(RAILS_409_CODE)) {
        dispatch(setDefaultSubmitStatus());
        return;
      }
      dispatch(setDefaultSubmitStatus());
      dispatch(updateReportStatus(props.patientId, "cancel"));
    };
  }, []);

  const hideReviewSubmitErrorModal = () => {
    if (isSubmittingReview) {
      return;
    }
    setIsSubmittingReviewFailed(false);
    setIsSubmit(0);
    dispatch(setDefaultSubmitStatus());
  };

  const retrySubmittingReview = () => {
    handleSubmit();
    setIsSubmittingReviewFailed(false);
    dispatch(setDefaultSubmitStatus());
  };

  function selectPvc(id) {
    id === 0 ? setPvc("None") : id === 1 ? setPvc("1 PVC") : setPvc("2+ PVCs");
  }

  function selectPav(id) {
    id === 1 ? setPav("None") : id === 2 ? setPav("1 PAC") : setPav("2+ PACs");
  }

  function selectQac(id) {
    id === 0 ? setQac("Normal") : setQac("Wide");
  }

  const textNotesInputHandeler = (e) => {
    setTextNotes(e.target.value);
  };

  const cancelHandler = () => {
    setIsCancelClicked(true);
  };

  const hideModal = () => {
    setIsCancelClicked(false);
  };

  const hideModalNetwork = () => {
    setIsNetworkError(false);
  };

  const confirmCancelHandler = () => {
    history.replace(CLINICIAN_ECGLIST);
  };

  const retryHandler = () => {
    setIsNetworkError(false);
    setTimeout(() => {
      handleSubmit();
    }, 300);
  };

  const remainingCharacterCount = () => {
    return 1000 - textNotes.length + " characters left";
  };
  const mapAvBlocksValue = (av) => {
    switch (av) {
      case "firstdegree":
        return "1st Degree";
      case "seconddegree":
        return "2nd Degree";
      case "thirddegree":
        return "3rd Degree";
      case "none":
        return "None";
      default:
        return "";
    }
  };

  function handleSubmit() {
    setIsSubmit(1);
    if (
      !baseRhythm ||
      !pav ||
      !pvc ||
      !qac ||
      !textNotes ||
      !acuity ||
      !(none || seconddegree || firstdegree || thirddegree)
    ) {
      return;
    }
    if (!window.navigator.onLine) {
      setIsSubmittingReviewFailed(false);
      setIsNetworkError(true);
      return;
    }
    dispatch(submitPatientReportId(props.reportId));
    let avBlockValues = [];
    Object.keys(avblocks).forEach((item) => {
      if (avblocks[item] === true) {
        avBlockValues.push(mapAvBlocksValue(item));
      }
    });
    const review = {
      rhythmId: baseRhythm,
      pvcs: pvc,
      pacs: pav,
      qrs: qac,
      notes: textNotes,
      acuityId: acuity,
      patientId: parseInt(props.patientId),
      memberFirstName: profile.firstName,
      memberLastName: profile.lastName,
      avBlocks: avBlockValues,
    };
    if (!isSubmittingReview) {
      dispatch(reviewSubmit(review));
    }
  }

  const acuityOrBaseRhythmErrorHandler = () => {
    window.location.reload();
  };

  const handleRedirectToWorkList = () => {
    setShowRedirectToWorkListModal(false);
    history.replace(CLINICIAN_ECGLIST);
  };
  const handleAvBlocksChange = (event) => {
    setAvBlocks({ ...avblocks, [event.target.name]: event.target.checked });
    if (event.target.name === "none") {
      setSelectedAvBlock(!selectedAvBlock);
      setAvBlocks({
        none: event.target.checked,
        seconddegree: false,
        firstdegree: false,
        thirddegree: false,
      });
    }
  };

  return (
    <React.Fragment>
      <Paper elevation={0} className={classes.root}>
        <Grid container className={classes.container}>
          <Grid item xs={5} className={classes.leftMostGridItem}>
            <Box className={classes.rootBox}>
              <Typography component="h4" className={classes.heading}>
                Secondary Observations:
              </Typography>
              <Box className={classes.secondaryObservationDiv}>
                <Typography component="p" className={classes.buttonHeading}>
                  PVCs:
                </Typography>
                <Box className={classes.buttonPillContainer}>
                  <Button
                    className={
                      pvc === "None" ? classes.selectedpill : classes.pill
                    }
                    classes={{
                      root: classes.commonButton,
                    }}
                    onClick={() => selectPvc(0)}
                  >
                    None
                  </Button>
                  <Button
                    className={
                      pvc === "1 PVC" ? classes.selectedpill : classes.pill
                    }
                    classes={{
                      root: classes.commonButton,
                    }}
                    onClick={() => selectPvc(1)}
                  >
                    1 PVC
                  </Button>
                  <Button
                    className={
                      pvc === "2+ PVCs" ? classes.selectedpill : classes.pill
                    }
                    classes={{
                      root: classes.commonButton,
                    }}
                    onClick={() => selectPvc(2)}
                  >
                    2+ PVCs
                  </Button>
                </Box>
                {pvc === "" && !!isSubmit ? (
                  <Typography className={classes.errorMessage}>
                    This is a required field
                  </Typography>
                ) : null}
              </Box>
              <Box className={classes.secondaryObservationDiv}>
                <Typography component="p" className={classes.buttonHeading}>
                  PACs:
                </Typography>
                <Box className={classes.buttonPillContainer}>
                  <Button
                    className={
                      pav === "None" ? classes.selectedpill : classes.pill
                    }
                    classes={{
                      root: classes.commonButton,
                    }}
                    onClick={(e) => selectPav(1)}
                  >
                    None
                  </Button>
                  <Button
                    className={
                      pav === "1 PAC" ? classes.selectedpill : classes.pill
                    }
                    classes={{
                      root: classes.commonButton,
                    }}
                    onClick={(e) => selectPav(2)}
                  >
                    1 PAC
                  </Button>
                  <Button
                    className={
                      pav === "2+ PACs" ? classes.selectedpill : classes.pill
                    }
                    classes={{
                      root: classes.commonButton,
                    }}
                    onClick={(e) => selectPav(3)}
                  >
                    2+ PACs
                  </Button>
                </Box>
                {pav === "" && isSubmit ? (
                  <Typography className={classes.errorMessage}>
                    This is a required field
                  </Typography>
                ) : null}
              </Box>
              <Box className={classes.secondaryObservationDiv}>
                <Typography component="p" className={classes.buttonHeading}>
                  QRS:
                </Typography>
                <Box className={classes.buttonPillContainer}>
                  <Button
                    className={
                      qac === "Normal" ? classes.selectedpill : classes.pill
                    }
                    classes={{
                      root: classes.commonButton,
                    }}
                    onClick={(e) => selectQac(0)}
                  >
                    Normal
                  </Button>
                  <Button
                    className={
                      qac === "Wide" ? classes.selectedpill : classes.pill
                    }
                    classes={{
                      root: classes.commonButton,
                    }}
                    onClick={(e) => selectQac(1)}
                  >
                    Wide
                  </Button>
                </Box>
                {qac === "" && !!isSubmit ? (
                  <Typography className={classes.errorMessage}>
                    This is a required field
                  </Typography>
                ) : null}
              </Box>
            </Box>
            <Box
              className={classes.secondaryObservationDiv}
              style={{ minWidth: "350px" }}
            >
              <Typography
                component="p"
                className={`${classes.buttonHeading} ${classes.rootBox}`}
              >
                Av Blocks:
              </Typography>
              <FormControl
                className={`${classes.formControl} ${classes.avBlockContainer}`}
              >
                <FormGroup className={classes.avFirstBlock}>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={none}
                        onChange={handleAvBlocksChange}
                        name="none"
                      />
                    }
                    label="None"
                    classes={{ label: classes.avBlockText }}
                  />
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={seconddegree}
                        onChange={handleAvBlocksChange}
                        disabled={selectedAvBlock}
                        name="seconddegree"
                      />
                    }
                    label="2nd Degree"
                    classes={{ label: classes.avBlockText }}
                  />
                </FormGroup>
                <FormGroup className={classes.avSecondBlock}>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={firstdegree}
                        onChange={handleAvBlocksChange}
                        disabled={selectedAvBlock}
                        name="firstdegree"
                      />
                    }
                    label="1st Degree"
                    classes={{ label: classes.avBlockText }}
                  />
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={thirddegree}
                        disabled={selectedAvBlock}
                        onChange={handleAvBlocksChange}
                        name="thirddegree"
                      />
                    }
                    label="3rd Degree"
                    classes={{ label: classes.avBlockText }}
                  />
                </FormGroup>
              </FormControl>
              {!(none || seconddegree || firstdegree || thirddegree) &&
                !!isSubmit && (
                  <Typography
                    className={`${classes.errorMessage} ${classes.avBlockError}`}
                  >
                    This is a required field
                  </Typography>
                )}
            </Box>
          </Grid>
          <Grid item xs={5} className={classes.notesGridContainer}>
            <Box
              className={`${classes.notesAcuityContainer} ${
                errorState ? classes.errorState : ""
              }`}
            >
              <Box className={classes.acuityContainer}>
                <Typography component="h4" className={classes.heading}>
                  Base Rhythm:
                </Typography>
                <SelectBox
                  value={baseRhythm}
                  onSelect={setBaseRhythm}
                  menuOptionsData={baseRhythmData}
                  bigScrollableMenu={true}
                  placeholder="Select Base Rhythm"
                  keyName="rhythmName"
                  errorState={baseRhythm === "" && !!isSubmit}
                />
                {baseRhythm === "" && !!isSubmit && (
                  <Typography className={classes.errorMessage}>
                    This is a required field
                  </Typography>
                )}
              </Box>
              <Box className={classes.acuityContainer}>
                <Typography component="h4" className={classes.heading}>
                  Acuity:
                </Typography>
                <SelectBox
                  value={acuity}
                  onSelect={setAcuity}
                  menuOptionsData={acuityMapper(acuityData)}
                  placeholder="None"
                  keyName="acuityValue"
                  errorState={acuity === "" && !!isSubmit}
                />
                {acuity === "" && !!isSubmit && (
                  <Typography className={classes.errorMessage}>
                    This is a required field
                  </Typography>
                )}
              </Box>
              <Box className={classes.topMargin}>
                <Typography component="h4" className={classes.heading}>
                  Notes and Recommendations:
                </Typography>
                <TextareaAutosize
                  aria-label="minimum height"
                  onChange={textNotesInputHandeler}
                  maxLength={1000}
                  value={textNotes}
                  placeholder="Enter notes and recommendations here"
                  style={
                    textNotes === "" && isSubmit
                      ? {
                          width: "100%",
                          height: "118px",
                          fontFamily: "Work Sans",
                          border: "1px solid red",
                          borderRadius: "4px",
                          wordBreak: "break-word",
                        }
                      : {
                          width: "100%",
                          height: "118px",
                          overflow: "auto",
                          fontFamily: "Work Sans",
                          fontSize: "16px",
                          lineHeight: "20px",
                          border: "1px solid rgba(20, 42, 57, 0.16)",
                          borderRadius: "4px",
                          wordBreak: "break-word",
                        }
                  }
                />
                {textNotes.length > 0 && (
                  <Typography component="p" className={classes.notesLimit}>
                    {remainingCharacterCount()}
                  </Typography>
                )}
              </Box>
              {textNotes === "" && !!isSubmit && (
                <Typography className={classes.errorMessage}>
                  This is a required field
                </Typography>
              )}
            </Box>
            <Box className={classes.buttonContainer}>
              <Button
                onClick={cancelHandler}
                className={`${classes.commonButtonStyles} ${classes.cancelButton}`}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                className={`${classes.commonButtonStyles} ${classes.submitButton}`}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <CustomModal
        modalType="alert"
        open={errorGettingBaseRhythm || errorGettingAcuity}
        closeModalHandler={acuityOrBaseRhythmErrorHandler}
        icon={WarningIcon}
        iconAltText="Warning Icon"
        heading="Something Went Wrong!"
        descriptionLine1="Try Refreshing the Page"
        confirmHandler={acuityOrBaseRhythmErrorHandler}
        confirmButtonText="Refresh"
      />
      <CustomModal
        modalType="confirm"
        open={isCancelClicked}
        closeModalHandler={hideModal}
        icon={WarningIcon}
        iconAltText="Warning Icon"
        heading="Review Not Submitted"
        descriptionLine1="Are you sure you want to leave?"
        descriptionLine2="If you do, all progress will be lost and EKG will return to your
        worklist."
        confirmHandler={confirmCancelHandler}
        confirmButtonText="YES, LEAVE"
        declineButtonText="NO, CANCEL"
      />
      <CustomModal
        modalType="confirm"
        open={isNetworkError}
        closeModalHandler={hideModalNetwork}
        icon={WarningIcon}
        iconAltText="Warning Icon"
        heading="Network Issue"
        descriptionLine1="There was an issue when you attempted to submit the report."
        descriptionLine2="Please check your internet connection and then try again."
        confirmHandler={retryHandler}
        confirmButtonText="TRY AGAIN"
        declineButtonText="CANCEL"
      />
      <CustomModal
        modalType="confirm"
        open={isSubmittingReviewFailed}
        closeModalHandler={hideReviewSubmitErrorModal}
        icon={WarningIcon}
        iconAltText="Warning Icon"
        heading="Failed to Submit Review"
        descriptionLine1="There was an issue when you attempted to submit the report."
        descriptionLine2="Please Try Again."
        confirmHandler={retrySubmittingReview}
        confirmButtonText="TRY AGAIN"
        declineButtonText="CANCEL"
      />
      {showRedirectToWorkListModal ? (
        <CustomModal
          omitClose={true}
          modalType="alert"
          open={showRedirectToWorkListModal}
          closeModalHandler={handleRedirectToWorkList}
          icon={WarningIcon}
          iconAltText="Warning Icon"
          heading="Report Processed By Another User"
          descriptionLine1="This error has been sent to the technical team."
          confirmHandler={handleRedirectToWorkList}
          confirmButtonText="Return to worklist"
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default ClinicianInteraction;
