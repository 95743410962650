// DO NOT TOUCH THESE NUMBERS UNLESS YOU KNOW WHAT YOU ARE DOING

// these are needed for rendering on headless webkit
const QT_WEBKIT_SCREEN_WIDTH = 800
const QT_WEBKIT_PX_PER_MM = 0.00463177397

export const PX_PER_MM = window.innerWidth > 0 ? 4
  : QT_WEBKIT_SCREEN_WIDTH * QT_WEBKIT_PX_PER_MM

export const GAIN_MM_PER_MV = 10
export const MV_BANDWIDTH = 2.5 // +-2.5 mV for a total 5mV "swing"
export const PAPER_SPEED = 25

export const PX_PER_MV = GAIN_MM_PER_MV * PX_PER_MM
export const PX_PER_SECOND = PAPER_SPEED * PX_PER_MM

export const SAMPLES_PER_PIXEL = 3
export const SAMPLE_UNITS_PER_MV = 2000

export const SVG_OFFSET = 0.5

export const CALPULSE_LENGTH_SECONDS = 0.5
export const CALPULSE_LENGTH_SAMPLES = 150
