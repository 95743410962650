import * as types from "./types";
import { getToken } from "../../Auth/AuthUtilities";
import { CrpAPI } from "../../Axios/axios";

export const setSearchButtonClicked = (boolean) => {
  return {
    type: types.IS_SEARCH_BUTTON_CLICKED,
    payload: boolean,
  };
};

export const isFetchingSearchResults = (status) => {
  return {
    type: types.IS_FETCHING_SEARCH_RESULTS,
    payload: status,
  };
};

export const errorRetrievingSearchList = (status) => {
  return {
    type: types.ERROR_SEARCHING_RESULTS,
    payload: status,
  };
};

export const getSearchData = (search = "", status) => {
  let searchAPIStatus = "";
  if (status === 1) {
    searchAPIStatus = "INCOMMING";
  }
  if (status === 2) {
    searchAPIStatus = "COMPLETED";
  }
  return (dispatch) => {
    dispatch(isFetchingSearchResults(true));
    CrpAPI.get(`searchlist?search=${search}&status=${searchAPIStatus}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: types.GETSEARCHDATA_SUCCESS,
            payload: res.data.data,
          });
          dispatch(isFetchingSearchResults(false));
          dispatch(errorRetrievingSearchList(false));
        }
      })
      .catch((err) => {
        dispatch(isFetchingSearchResults(false));
        dispatch(errorRetrievingSearchList(true));
      });
  };
};
