import { makeStyles } from "@material-ui/core/styles";
import { snowWhite } from "../../consts/colors";
import { fontFamily } from "../../Assets/styles/commonStyles";

export const useStyles = makeStyles({
  container: {
    height: "100vh",
    overflow: "auto",
    backgroundColor: snowWhite,
    padding: "1rem",
    paddingTop: "0",
  },
  topMargin: {
    marginTop: "2rem",
  },
  paperRoot: {
    marginTop: "1.5rem",
    padding: "2.5rem",
    marginBottom: "2.5rem",
  },
  heading: {
    fontFamily,
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "28px",
  },
  subHeading: {
    fontFamily,
    marginBottom: "1.5rem",
    fontWeight: "600",
    fontSize: "22px",
  },
  generalSubHeading: {
    marginTop: "2rem",
  },
  otherContentSubeading: {
    marginTop: "3rem",
  },
});
