export const columns = [
  {
    id: "name",
    label: "Name",
    disableSorting: 1,
  },
  {
    id: "reportId",
    label: "Report ID",
    disableSorting: 1,
  },
  {
    id: "interpretation",
    label: "Interpretation",
    disableSorting: 1,
  },
  {
    id: "timeRequesteduested",
    label: "Time Requested",
    disableSorting: 0,
  },
  {
    id: "timeLeft",
    label: "Time Left",
    disableSorting: 0,
  },
  {
    id: "status",
    label: "Status",
    disableSorting: 1,
  },
  {
    id: "location",
    label: "Location",
    disableSorting: 1,
  },
];
