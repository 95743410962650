import { makeStyles } from "@material-ui/core/styles";
import { fontFamily } from "../../Assets/styles/commonStyles";
import {
  mintCream,
  white,
  bluishGrey,
  pineGreen,
  pantoneRed,
  mehendiGrey,
} from "../../consts/colors";

export const useStyles = makeStyles({
  selectRoot: {
    paddingLeft: "0.5rem",
    "&:focus": {
      backgroundColor: white,
    },
  },
  selectListPadding: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  selectMenu: {
    marginTop: "4px",
  },
  scrollableMenu: {
    marginTop: "4px",
    height: "170px",
    overflow: "auto",
    overflowX: "hidden",
  },
  bigScrollableMenu: {
    height: 370,
    overflowX: "hidden",
    overflowY: "auto",
    marginTop: 4,
    width: "282px",
  },
  inputBox: {
    backgroundColor: white,
    border: `1px solid ${bluishGrey}`,
    boxShadow: "none",
    width: "100%",
    height: "40px",
    borderRadius: "4px",
    fontFamily,
    fontSize: "16px",
    lineHeight: "20px",
    "&:hover": {
      border: `1px solid ${bluishGrey}`,
      backgroundColor: "transparent",
    },
  },
  menuOptions: {
    height: "40px",
    fontFamily,
    fontSize: "16px",
    lineHeight: "20px",
    display: "flex",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: mintCream,
    },
    "&:selected": {
      backgroundColor: white,
    },
    "&:focus": {
      backgroundColor: mintCream,
    },
  },
  selectIcon: {
    fill: pineGreen,
  },
  errorInputBox: {
    backgroundColor: white,
    border: `1px solid ${pantoneRed}`,
  },
  menuItemsRoot: {
    whiteSpace: "break-spaces",
  },
  underLinedMenuOptions: {
    borderBottom: `1px solid ${mehendiGrey}`,
    height: "50px",
    marginLeft: "4px",
    marginRight: "4px",
  },
});
