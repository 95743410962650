import { makeStyles } from "@material-ui/core/styles";
import { black } from "../../consts/colors";
import { fontFamily } from "../../Assets/styles/commonStyles";

export const useStyles = makeStyles({
  container: {
    marginBottom: "2.5rem",
  },
  heading: {
    fontFamily,
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "23px",
    color: black,
  },
  description: {
    fontFamily,
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "-0.2px",
    marginTop: "9px",
  },
});
