import * as types from "./types";
import { getToken } from "../../Auth/AuthUtilities";
import { CrpAPI } from "../../Axios/axios";

const isFetchingResourceData = (boolean) => {
  return {
    type: types.IS_FETCHING_RESOURCE_DATA,
    payload: boolean,
  };
};

const isErrorGettingResourceData = (boolean) => {
  return {
    type: types.IS_ERROR_GETTING_RESOURCE_DATA,
    payload: boolean,
  };
};

export const getResourceData = (
  isCrpAdmin,
  isCrpPhysician,
  isTeleconnectAdmin,
  isTeleconnectPhysician
) => {
  let crpAdmin = "";
  let crpPhysician = "";
  let teleconnectAdmin = "";
  let teleconnectPhysician = "";

  if (isCrpAdmin) {
    crpAdmin = "CrpAdmin";
  }
  if (isCrpPhysician) {
    crpPhysician = "CrpPhysician";
  }
  if (isTeleconnectAdmin) {
    teleconnectAdmin = "TelConnectAdmin";
  }
  if (isTeleconnectPhysician) {
    teleconnectPhysician = "TelConnectPhysician";
  }
  return (dispatch) => {
    dispatch(isFetchingResourceData(true));
    CrpAPI.get(
      `resource?role=${crpAdmin}${crpAdmin?',':''}${teleconnectAdmin}${teleconnectAdmin?',':''}${crpPhysician}${crpPhysician?',':''}${teleconnectPhysician}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          dispatch(isFetchingResourceData(false));
          dispatch(isErrorGettingResourceData(false));
          dispatch({
            type: types.GET_RESOURCE_CONTENT,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        dispatch(isFetchingResourceData(false));
        dispatch(isErrorGettingResourceData(true));
      });
  };
};
