import * as types from "./types";
import { updateObject } from "../utility";

const initialState = {
  tabState: 1,
};

const setTabState = (state, action) => {
  return updateObject(state, {
    tabState: action.payload,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SETTABSTATE:
      return setTabState(state, action);
    default:
      return state;
  }
};

export default reducer;
