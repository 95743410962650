import React from "react";
import {
  DateRangePicker,
  DateRangeDelimiter,
  LocalizationProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import TextField from "@material-ui/core/TextField";
import { ThemeProvider } from "@material-ui/core/styles";
import { materialTheme } from "./CustomDateRangePickerStyles";

/* Usage
  CustomDateRangePicker expects 3 props => 
  1) selectedDate = can be a state which will hold selected Date
  2) setSelectedDate = function which will set the data
  3) setSelectedDateFilter (optional) 
*/

function CustomDateRangePicker(props) {
  const { selectedDate, setSelectedDate, setSelectedDateFilter, disabled } =
    props;

  const onChangeHandler = (date) => {
    setSelectedDate(date);
    setSelectedDateFilter(1);
  };

  return (
    <ThemeProvider theme={materialTheme}>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <DateRangePicker
          calendars={1}
          disableFuture={true}
          value={selectedDate}
          onChange={onChangeHandler}
          disabled={disabled}
          renderInput={(startProps, endProps) => (
            <>
              <TextField {...startProps} />
              <DateRangeDelimiter> to </DateRangeDelimiter>
              <TextField {...endProps} />
            </>
          )}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default CustomDateRangePicker;
