import { makeStyles } from "@material-ui/styles";
import {
  white,
  darkBluishGrey,
  lightOxfordBluishGrey,
} from "../../consts/colors";
import {
  fontFamily,
  secondaryFontFamily,
} from "../../Assets/styles/commonStyles";

export const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContainer: {
    width: "796px",
    backgroundColor: white,
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.12), 0px 6px 12px rgba(0, 0, 0, 0.12), 0px 1px 16px rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
    padding: "3rem",
    paddingBottom: "1rem",
  },
  modalWarningImage: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
    marginBottom: "2.5rem",
  },
  modalHeading: {
    fontFamily: secondaryFontFamily,
    fontWeight: "bold",
    fontSize: "22px",
    lineHeight: "33px",
    textAlign: "center",
    marginBottom: "1rem",
  },
  modalDescription: {
    fontFamily,
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
    width: "716px",
    wordBreak: "break-word",
  },
  modalButtonContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "2rem",
  },
  modalButton: {
    fontFamily: secondaryFontFamily,
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "21px",
    padding: "12px",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
  leaveButton: {
    backgroundColor: darkBluishGrey,
    color: white,
    "&:hover": {
      backgroundColor: lightOxfordBluishGrey,
    },
  },
  stayButton: {
    backgroundColor: white,
    color: darkBluishGrey,
    border: `1px solid ${darkBluishGrey}`,
  },
});
